import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component, createRef } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { getAuthToken, getUserDetails, setAuthToken } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { GetRatingBand } from '../../helpers/global';
import { fieldRequired } from '../../helpers/validation';
import Attachments from './Common/Attachments';
import EngagementModal from './EngagementModal';
import Findings from './Common/Findings';
import RiskEvaluate from './RiskEvaluation';
import { RiskPlan } from './RiskPlan';
import { RiskTreat } from './RiskTreat';


export class Risk extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.objectivesStatusRef = createRef();
    this.processesStatusRef = createRef();
    this.capitalStatusRef = createRef();
    this.stakeholderStatusRef = createRef();
    this.rootCausesStatusRef = createRef();
    this.controlsStatusRef = createRef();
    this.consequencesStatusRef = createRef();
    this.analysisStatusRef = createRef();

    const commandTemplate = [
      { type: 'View', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } },
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];
    const controlCommandTemplate = [
      //{ type: 'View', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } },
      { type: 'Engage', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.state = {
      editData: [], loading: true, editModal: false, objectivesModal: false, capitalsModal: false, stakeholdersModal: false, processesModal: false, rootCausesModal: false, controlsModal: false, controlsEditModal: false, consequencesModal: false, controlRootModal: false, controlConsequenceModal: false,
      objectivesStatusModal: false, capitalsStatusModal: false, stakeholdersStatusModal: false, processesStatusModal: false, rootCauseStatusModal: false, controlStatusModal: false, consequenceStatusModal: false, perspectiveConsequenceModal: false, gridCommands: commandTemplate, controlGridCommands: controlCommandTemplate, gridToolbar: ['Search'],
      RiskBusinessObjectiveStatusData: {},
      Id: 0,
      CompanyId: 0,
      RiskNumber: '',
      Name: '',
      Description: '',
      RiskTypeId: 0,
      RiskCategoryId: 0,
      RiskStatusId: 0,
      SiteStructureId: 0,
      BusinessObjectiveId: 0,
      BusinessProcessId: 0,
      CapitalId: 0,
      ControlId: 0,
      StakeholderId: 0,
      SpeedOfOnset: 0,
      Percentage: 0,
      RiskAnalysisStatusId: 0,
      Comment: '',
      ResponsiblePersonId: 0,
      ResponsibleTeamId: 0,
      HasOpportunities: false,
      Opportunities: '',
      HasIndicators: false,
      Indicators: '',
      RiskAnalysis: '',
      DeactivationReason: '',
      DateAdded: new Date(),
      AddedBy: 0,
      AddedByTeamId: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      CurrentView: 0,
      CompanyList: [],
      RiskCategoryList: [],
      RiskTypeList: [],
      RiskStatusList: [],
      //RiskTypeList: [],
      SiteStructureList: [],
      TeamList: [],
      UsersList: [],
      RootCausesList: [],
      ConsequencesList: [],
      RiskAnalysisStatusData: [],
      PerspectivesList: [],
      ConnectedPerspectives: [],
      SelectedItem: 0,
      Attachments: [{
        FileName: 'Clarification.doc',
        FileDescription: 'Clarification'
      }]

    };
    this.toggle = this.toggle.bind(this);
    this.toggleRiskAnalysis = this.toggleRiskAnalysis.bind(this);
    this.toggleObjectives = this.toggleObjectives.bind(this);
    this.toggleProcesses = this.toggleProcesses.bind(this);
    this.toggleCapitals = this.toggleCapitals.bind(this);
    this.toggleStakeholders = this.toggleStakeholders.bind(this);
    this.toggleRootCauses = this.toggleRootCauses.bind(this);
    this.toggleControls = this.toggleControls.bind(this);
    this.toggleControlsEdit = this.toggleControlsEdit.bind(this);
    this.toggleConsequences = this.toggleConsequences.bind(this);
    this.toggleObjectivesStatus = this.toggleObjectivesStatus.bind(this);
    this.toggleProcessesStatus = this.toggleProcessesStatus.bind(this);
    this.toggleCapitalsStatus = this.toggleCapitalsStatus.bind(this);
    this.toggleStakeholdersStatus = this.toggleStakeholdersStatus.bind(this);
    this.toggleRootCauseStatus = this.toggleRootCauseStatus.bind(this);
    this.toggleControlStatus = this.toggleControlStatus.bind(this);
    this.toggleConsequenceStatus = this.toggleConsequenceStatus.bind(this);
    this.toggleControlRoot = this.toggleControlRoot.bind(this);
    this.toggleControlConsequence = this.toggleControlConsequence.bind(this);
    this.togglePerspectiveConsequence = this.togglePerspectiveConsequence.bind(this);
    this.loadData = this.loadData.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  toggleRiskAnalysis() {
    this.setState({
      riskAnalysisModal: !this.state.riskAnalysisModal
    });
  }

  toggleObjectives() {
    this.setState({
      objectivesModal: !this.state.objectivesModal
    });
  }

  toggleProcesses() {
    this.setState({
      processesModal: !this.state.processesModal
    });
  }

  toggleCapitals() {
    this.setState({
      capitalsModal: !this.state.capitalsModal
    });
  }

  toggleStakeholders() {
    this.setState({
      stakeholdersModal: !this.state.stakeholdersModal
    });
  }

  toggleConsequences() {
    this.setState({
      consequencesModal: !this.state.consequencesModal
    });
  }

  toggleControls() {
    this.setState({
      controlsModal: !this.state.controlsModal
    });
  }

  toggleControlsEdit() {
    this.setState({
      controlsEditModal: !this.state.controlsEditModal
    });
  }

  toggleControlRoot() {
    this.setState({
      controlRootModal: !this.state.controlRootModal
    });
  }

  toggleControlConsequence() {
    this.setState({
      controlConsequenceModal: !this.state.controlConsequenceModal
    });
  }

  toggleRootCauses() {
    this.setState({
      rootCausesModal: !this.state.rootCausesModal
    });
  }

  toggleObjectivesStatus() {
    this.setState({
      objectivesStatusModal: !this.state.objectivesStatusModal
    });
  }

  toggleProcessesStatus() {
    this.setState({
      processesStatusModal: !this.state.processesStatusModal
    });
  }

  toggleCapitalsStatus() {
    this.setState({
      capitalsStatusModal: !this.state.capitalsStatusModal
    });
  }

  toggleStakeholdersStatus() {
    this.setState({
      stakeholdersStatusModal: !this.state.stakeholdersStatusModal
    });
  }

  toggleRootCauseStatus() {
    this.setState({
      rootCauseStatusModal: !this.state.rootCauseStatusModal
    });
  }

  toggleControlStatus() {
    this.setState({
      controlStatusModal: !this.state.controlStatusModal
    });
  }

  toggleConsequenceStatus() {
    this.setState({
      consequenceStatusModal: !this.state.consequenceStatusModal
    });
  }

  togglePerspectiveConsequence() {
    this.setState({
      perspectiveConsequenceModal: !this.state.perspectiveConsequenceModal
    });
  }

  componentDidMount() {
    document.title = "Risk Edit Administration";
    this.loadData();
  }

  editItem = (id, status) => {
    //let modalContent;

    switch (status) {
      case "Analysis":
        this.setState({
          PreviousPercentage: this.state.RiskAnalysisStatusData.Percentage,
          UserName: this.state.RiskAnalysisStatusData.UserName,
          PreviousDate: new Date(this.state.RiskAnalysisStatusData.DateModified).toDateString(),
          PreviousComment: this.state.RiskAnalysisStatusData.Note
        });
        if (this.analysisStatusRef.current) {
          this.analysisStatusRef.current.showModal();
        }
        break;
      case "Objectives":
        this.setState({
          PreviousPercentage: this.state.RiskBusinessObjectiveStatusData.Percentage,
          UserName: this.state.RiskBusinessObjectiveStatusData.UserName,
          PreviousDate: new Date(this.state.RiskBusinessObjectiveStatusData.DateModified).toDateString(),
          PreviousComment: this.state.RiskBusinessObjectiveStatusData.Note
        });
        if (this.objectivesStatusRef.current) {
          this.objectivesStatusRef.current.showModal();
        }
        break;
      case "Processes":
        this.setState({
          PreviousPercentage: this.state.RiskBusinessProcessStatusData.Percentage,
          UserName: this.state.RiskBusinessProcessStatusData.UserName,
          PreviousDate: new Date(this.state.RiskBusinessProcessStatusData.DateModified).toDateString(),
          PreviousComment: this.state.RiskBusinessProcessStatusData.Note
        });
        if (this.processesStatusRef.current) {
          this.processesStatusRef.current.showModal();
        }

        break;
      case "Capitals":
        this.setState({
          PreviousPercentage: this.state.RiskCapitalStatusData.Percentage,
          UserName: this.state.RiskCapitalStatusData.UserName,
          PreviousDate: new Date(this.state.RiskCapitalStatusData.DateModified).toDateString(),
          PreviousComment: this.state.RiskCapitalStatusData.Note
        });
        if (this.capitalStatusRef.current) {
          this.capitalStatusRef.current.showModal();
        }
        break;
      case "Stakeholders":
        this.setState({
          PreviousPercentage: this.state.RiskStakeholderStatusData.Percentage,
          UserName: this.state.RiskStakeholderStatusData.UserName,
          PreviousDate: new Date(this.state.RiskStakeholderStatusData.DateModified).toDateString(),
          PreviousComment: this.state.RiskStakeholderStatusData.Note
        });
        if (this.stakeholderStatusRef.current) {
          this.stakeholderStatusRef.current.showModal();
        }
        break;
      case "Root":
        this.setState({
          //rootCauseStatusModal: true,
          PreviousPercentage: this.state.RootCauseStatusData.Percentage,
          UserName: this.state.RootCauseStatusData.UserName,
          PreviousDate: new Date(this.state.RootCauseStatusData.DateModified).toDateString(),
          PreviousComment: this.state.RootCauseStatusData.Note
        });
        if (this.rootCausesStatusRef.current) {
          this.rootCausesStatusRef.current.showModal();
        }
        break;
      case "Controls":
        this.setState({
          //controlStatusModal: true,
          PreviousPercentage: this.state.ControlStatusData.Percentage,
          UserName: this.state.ControlStatusData.UserName,
          PreviousDate: new Date(this.state.ControlStatusData.DateModified).toDateString(),
          PreviousComment: this.state.ControlStatusData.Note
        });
        if (this.controlsStatusRef.current) {
          this.controlsStatusRef.current.showModal();
        }
        break;
      case "Consequences":
        this.setState({
          //consequenceStatusModal: true,
          PreviousPercentage: this.state.ConsequenceStatusData.Percentage,
          UserName: this.state.ConsequenceStatusData.UserName,
          PreviousDate: new Date(this.state.ConsequenceStatusData.DateModified).toDateString(),
          PreviousComment: this.state.ConsequenceStatusData.Note
        });
        if (this.consequencesStatusRef.current) {
          this.consequencesStatusRef.current.showModal();
        }
        break;
      default:
        return null;
    }
  };

  handleRootCauseChange = (id) => {
    const { ConnectedRootCauses } = this.state;

    const updatedConnectedRootCauses = ConnectedRootCauses?.includes(id)
      ? ConnectedRootCauses.filter((causeId) => causeId !== id)
      : [...ConnectedRootCauses, id];

    this.setState({ ConnectedRootCauses: updatedConnectedRootCauses });
  };

  handleConsequenceChange = (id) => {
    const { ConnectedConsequences } = this.state;

    const updatedConnectedConsequences = ConnectedConsequences?.includes(id)
      ? ConnectedConsequences.filter((consequenceId) => consequenceId !== id)
      : [...ConnectedConsequences, id];

    this.setState({ ConnectedConsequences: updatedConnectedConsequences });
  };

  handlePerspectiveChange = (id) => {
    const { ConnectedPerspectives } = this.state;

    const updatedConnectedPerspectives = ConnectedPerspectives?.includes(id)
      ? ConnectedPerspectives.filter((perspectiveId) => perspectiveId !== id)
      : [...ConnectedPerspectives, id];

    this.setState({ ConnectedPerspectives: updatedConnectedPerspectives });
  };

  handleUpdate = (selectedItem, currentView) => {
    const { setCurrentView } = this.context;
    this.setState({ SelectedItem: selectedItem, CurrentView: currentView })
    setCurrentView(currentView)

  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.Name, 'tbNameError', '* required');
    valid &= fieldRequired(this.state.Description, 'tbDescriptionError', '* required');
    valid &= fieldRequired(this.state.RiskTypeId, 'tbRiskTypeIdError', '* required');
    valid &= fieldRequired(this.state.RiskCategoryId, 'tbRiskCategoryIdError', '* required');
    valid &= fieldRequired(this.state.RiskStatusId, 'tbRiskStatusIdError', '* required');
    valid &= fieldRequired(this.state.SiteStructureId, 'tbSiteStructureIdError', '* required');
    valid &= fieldRequired(this.state.SpeedOfOnset, 'tbSpeedOfOnsetError', '* required');
    valid &= fieldRequired(this.state.ResponsiblePersonId, 'tbResponsiblePersonIdError', '* required');
    valid &= fieldRequired(this.state.ResponsibleTeamId, 'tbResponsibleTeamIdError', '* required');
    valid &= fieldRequired(this.state.DeactivationReason, 'tbDeactivationReasonError', '* required');
    valid &= fieldRequired(this.state.AddedByTeamId, 'tbAddedByTeamIdError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.Id === this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.setState({
          DateAdded: data.DateAdded,
          AddedBy: data.AddedBy,
          Id: data.Id,
          Status: data.Status
        });
      }
      else {
        this.setState({
          Status: 1
        });
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id, route) => {
    const { handleNavigate } = this.context;
    this.setState({ controlsModal: false });
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    //console.log(id, route)
    const path = route;
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.setState({ loading: true });

      var bearer = 'Bearer ' + getAuthToken();
      try {
        const response = await fetch(`${path}/` + id, {
          method: 'DELETE',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          }
        });
        if (response.ok) {
          await response.json();
          this.loadData();
          ToastUtility.show({
            title: 'Risk Engagement', content: 'The item was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
          });
        }
        else {
          console.error(response.status + ": " + response.statusText);
          if (response.status === 401)
            handleNavigate("/");
        }
      } catch (e) {
        console.error(e);
        this.setState({ loading: false });
        ToastUtility.show({
          title: 'Risk Engagement', content: 'There was an error deleting the item!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
    } else {
      this.setState({ controlsModal: true });
    }
  }

  viewItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      this.setState({ Id: data.Id, CompanyId: data.CompanyId, RiskNumber: data.RiskNumber, Name: data.Name, Description: data.Description, RiskTypeId: data.RiskTypeId, RiskCategoryId: data.RiskCategoryId, RiskStatusId: data.RiskStatusId, SiteStructureId: data.SiteStructureId, SpeedOfOnset: data.SpeedOfOnset, ResponsiblePersonId: data.ResponsiblePersonId, ResponsibleTeamId: data.ResponsibleTeamId, HasOpportunities: data.HasOpportunities, Opportunities: data.Opportunities, HasIndicators: data.HasIndicators, Indicators: data.Indicators, DeactivationReason: data.DeactivationReason, DateAdded: data.SateAdded, AddedBy: data.AddedBy, AddedByTeamId: data.AddedByTeamId, DateModified: data.DateModified, ModifiedBy: data.ModifiedBy, Status: data.Status, });
    }
    this.setState({ editId: id, viewModal: true });
  };

  engageItem = (id) => {
    const userData = getUserDetails();

    if (id > 0) {
      const data = this.state.ControlsList.find((item) => { return item.Id === id });
      //console.log(data);
      if (userData.UserRole > 9 || data.ControlResponsiblePersonId === userData.UserRole || this.state.ResponsiblePersonId === userData.UserRole) {
        this.setState({
          editId: id,
          ControlId: data.Id,
          ControlNumber: data.ControlNumber,
          ShortName: data.ShortName,
          Assurances: data.Assurances,
          RiskDescription: data.Description,
          Critical: data.Critical,
          NeedsAssurance: data.NeedsAssurance,
          ControlResponsiblePersonId: data.ControlResponsiblePersonId,
          LevelId: data.LevelId,
          ITId: data.ITId,
          AutomatedId: data.AutomatedId,
          ConnectedRootCauses: data.ConnectedRootCauses,
          ConnectedConsequences: data.ConnectedConsequences,
        });
      } else {
        ToastUtility.show({
          title: 'Control', content: 'You do not have permission to edit this control!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        return;
      }
    } else {
      this.setState({
        editId: id,
        ControlId: 0,
        ControlNumber: '',
        ShortName: '',
        Assurances: [],
        RiskDescription: '',
        Critical: false,
        NeedsAssurance: false,
        ControlResponsiblePersonId: 0,
        LevelId: 0,
        ITId: 0,
        AutomatedId: 0,
        ConnectedRootCauses: [],
        ConnectedConsequences: [],
      })
    }
    this.toggleControls();
    this.toggleControlsEdit();
  };

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {

      case 'Delete':
        this.deleteItem(args.rowData.Id, 'api/controls/' + getUserDetails().UserRole);
        break;
      case 'View':
        this.viewItem(args.rowData.Id);
        break;
      case 'Engage':
        this.engageItem(args.rowData.Id);
        break;
      default: //edit
        this.editItem(args.rowData.Id);
        break;
    }
  }

  renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    console.log("Controls:", data)
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='ControlNumber' width='70' headerText="Control #" />
          <ColumnDirective field='ShortName' width='120' headerText="Short Name" />
          <ColumnDirective field='FindingCount' width='80' headerText="Findings" />
          <ColumnDirective field='AttachmentCount' width='80' headerText="Attachments" />
          <ColumnDirective field='Critical' width='80' headerText="Critical?" template={(props) =>
            props.NeedsAssurance === false && props.Critical ?
              <div style={{ width: "20px", height: "20px", backgroundColor: "orange" }}></div> : props.NeedsAssurance ?
                <div style={{ width: "20px", height: "20px", border: "1px grey solid", display: "grid", placeContent: "center" }}><i className="fa-solid fa-check fs-6"></i></div> :
                <div style={{ width: "20px", height: "20px", border: "1px grey solid" }}></div>
          } />
          <ColumnDirective field='NeedsAssurance' width='100' headerText="Needs Assurance?" template={(props) =>
            props.NeedsAssurance === false && props.Critical ?
              <div style={{ width: "20px", height: "20px", backgroundColor: "orange" }}></div> : props.NeedsAssurance ?
                <div style={{ width: "20px", height: "20px", border: "1px grey solid", display: "grid", placeContent: "center" }}><i className="fa-solid fa-check fs-6"></i></div> :
                <div style={{ width: "20px", height: "20px", border: "1px grey solid" }}></div>
          } />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  render() {
    const levels = [{ Title: "Unclassified", Id: 0 }, { Title: "Entity Level", Id: 1 }, { Title: "Activity Level", Id: 2 }];
    const isIT = [{ Title: "Unclassified", Id: 0 }, { Title: "IT General Control", Id: 1 }, { Title: "IT Application Control", Id: 2 }];
    const isAutomated = [{ Title: "Unclassified", Id: 0 }, { Title: "Automated", Id: 1 }, { Title: "Manual", Id: 2 }];
    const statuses = [{ Title: "Draft", Id: 0 }, { Title: "Active", Id: 1 }, { Title: "Deactivated", Id: 2 }];
    const Objectives = !this.state.loading && this.state.BusinessObjectivesList.filter(item => {
      return !this.state.RiskBusinessObjectiveData?.some(ob => ob.BusinessObjectiveId === item.Id);
    });

    const Processes = !this.state.loading && this.state.BusinessProcessesList.filter(item => {
      return !this.state.RiskBusinessProcessData?.some(ob => ob.BusinessProcessId === item.Id);
    });
    const Capitals = !this.state.loading && this.state.CapitalsList.filter(item => {
      return !this.state.RiskCapitalData?.some(ob => ob.CapitalsId === item.Id);
    });
    const Stakeholders = !this.state.loading && this.state.StakeholdersList.filter(item => {
      return !this.state.RiskStakeholderData?.some(ob => ob.StakeholderId === item.Id);
    });

    let currentSite = this.state.SiteStructureList.find(item => item.Id === this.state.SiteStructureId)
    //console.log(this.state);
    return (
      this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : <>
        <div className="container-fluid">
          <Row className='mb-3'>
            <Col xs={6}>
              <h1>Risk Engagement</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Link to="/my-items" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Risks</Link>
            </Col>
          </Row>
          <div className=''>
            <div className='card bg-light mb-3'>
              <div>
                <div className='d-flex risk-status justify-content-between my-3'>
                  <div className='d-flex'>
                    <Attachments Id={this.state.Id} Type={1} />
                    <Findings Id={this.state.Id} Type={1} />
                    <i className="fa-regular fa-circle-user text-danger" title={`Responsible Person: ${this.state.ResponsiblePersonName}\nResponsible Team: ${this.state.ResponsibleTeamName}`}></i>
                    <i className="fa-regular fa-circle-user me-2 text-primary" title={`Logging Person: ${this.state.AddedByName}\nLogging Team: ${this.state.AddedByTeamName}\nLogging Date: ${new Date(this.state.DateAdded).toDateString()}`} ></i>
                  </div>
                  <span className='fw-bold'> RISK # {this.state.RiskNumber}</span>
                  {/*<span className='risk-status-box' style={{ background: this.state.Status === 0 ? "lightgrey" : this.state.Status === 1 ? "limegreen" : "crimson", color: this.state.Status === 2 ? "#fff" : "#fff" }}>{statuses.find(item => item.Id === this.state.Status)?.Title}</span>*/}
                  <span className={'risk-status-box ' + (statuses.find(item => item.Id === this.state.Status)?.Title.toLowerCase())}>{statuses.find(item => item.Id === this.state.Status)?.Title}</span>
                </div>
                <div className='row'>
                  <div className='mb-3 col-md-8 col-lg-3'>
                    <div className="card risk-detail">
                      <div className="card-body">Short Name</div>
                      <div className="card-footer">{this.state.Name}</div>
                    </div>
                    {/*<TextBoxComponent placeholder='Short Name' type='text' disabled maxLength='2500' floatLabelType='Always' value={this.state.Name} />*/}
                  </div>
                  <div className='mb-3 col-md-4 col-lg-2'>
                    {/*<TextBoxComponent placeholder='Type' type='text' disabled maxLength='2500' floatLabelType='Always' value={this.state.RiskTypeList.find(item => item.Id === this.state.RiskTypeId)?.Title} />*/}
                    <div className="card risk-detail">
                      <div className="card-body">Type</div>
                      <div className="card-footer">{this.state.RiskTypeList.find(item => item.Id === this.state.RiskTypeId)?.Title}</div>
                    </div>
                  </div>
                  <div className='mb-3 col-md-5 col-lg-2'>
                    {/*<TextBoxComponent placeholder='Category' type='text' disabled maxLength='2500' floatLabelType='Always' value={this.state.RiskCategoryList.find(item => item.Id === this.state.RiskCategoryId)?.Title} />*/}
                    <div className="card risk-detail">
                      <div className="card-body">Category</div>
                      <div className="card-footer">{this.state.RiskCategoryList.find(item => item.Id === this.state.RiskCategoryId)?.Title}</div>
                    </div>
                  </div>
                  <div className='mb-3 col-md-5 col-lg-3'>
                    {/*<TextBoxComponent placeholder='Site' type='text' disabled maxLength='2500' floatLabelType='Always' value={this.state.SiteStructureList.find(item => item.Id === this.state.SiteStructureId)?.Name} />*/}
                    <div className="card risk-detail">
                      <div className="card-body">Site</div>
                      <div className="card-footer">{this.state.SiteStructureList.find(item => item.Id === this.state.SiteStructureId)?.Name}</div>
                    </div>
                  </div>
                  <div className='mb-3 col-md-2 col-lg-2'>
                    {/*<TextBoxComponent placeholder='Onset' type='text' disabled maxLength='2500' floatLabelType='Always' value={this.state.SpeedOfOnset + "m"} />*/}
                    <div className="card risk-detail">
                      <div className="card-body">Onset</div>
                      <div className="card-footer">{this.state.SpeedOfOnset + "m"}</div>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-around align-items-center flex-wrap'>

                  <div className='d-flex text-center risk-header mb-3 col-md'>
                    {this.state.PotentialActualTargetData ? <>
                      <div onClick={() => this.handleUpdate(2, 1)} style={{ cursor: "pointer" }} className='risk-box'>
                        <small>POTENTIAL</small>
                        {this.state.PotentialActualTargetData.PotentialData.OverallRating ? <div>{this.state.PotentialActualTargetData.PotentialData.OverallRating + " - " + GetRatingBand(this.state.PotentialActualTargetData.PotentialData.OverallRating, this.state.PotentialActualTargetData.RiskImpactRatings, currentSite.ImmaterialityLevel, currentSite.CatastrophicLevel, currentSite.NumOfLevels, currentSite.TypeId, this.state.PotentialActualTargetData.PotentialData.TotalLikelyImpact)}<br />{getUserDetails().Currency + " " + this.state.PotentialActualTargetData.PotentialData.TotalLikelyImpact.toNumberString()}</div>
                          : <div>NYS</div>}
                      </div>
                      <div onClick={() => this.handleUpdate(2, 2)} style={{ cursor: "pointer" }} className='risk-box'>
                        <small>ACTUAL</small>
                        {this.state.PotentialActualTargetData.ActualData.OverallRating ? <div>{this.state.PotentialActualTargetData.ActualData.OverallRating + " - " + GetRatingBand(this.state.PotentialActualTargetData.ActualData.OverallRating, this.state.PotentialActualTargetData.RiskImpactRatings, currentSite.ImmaterialityLevel, currentSite.CatastrophicLevel, currentSite.NumOfLevels, currentSite.TypeId, this.state.PotentialActualTargetData.ActualData.TotalLikelyImpact)}<br />{getUserDetails().Currency + " " + this.state.PotentialActualTargetData.ActualData.TotalLikelyImpact.toNumberString()}</div>
                          : <div>NYS</div>}
                      </div>
                      <div onClick={() => this.handleUpdate(2, 3)} style={{ cursor: "pointer" }} className='risk-box'>
                        <small>TARGET</small>
                        {this.state.PotentialActualTargetData.TargetData.OverallRating ? <div>{this.state.PotentialActualTargetData.TargetData.OverallRating + " - " + GetRatingBand(this.state.PotentialActualTargetData.TargetData.OverallRating, this.state.PotentialActualTargetData.RiskImpactRatings, currentSite.ImmaterialityLevel, currentSite.CatastrophicLevel, currentSite.NumOfLevels, currentSite.TypeId, this.state.PotentialActualTargetData.TargetData.TotalLikelyImpact)}<br />{getUserDetails().Currency + " " + this.state.PotentialActualTargetData.TargetData.TotalLikelyImpact.toNumberString()}</div>
                          : <div>NYS</div>}
                      </div></> : ""
                    }
                  </div>
                </div>
              </div>
              {/*<hr className='m-0' />*/}
              <div className="risk-heading">
                <div className='row py-3 '>
                  <div className='pb-3 col-md-9 text-center'>
                    <strong>Risk Management</strong>
                    {/*<div className='d-flex justify-content-center flex-wrap'>*/}
                    <div className='row justify-content-center'>
                      <div className='col-md-4'>
                        <small className="p-1" style={{ backgroundColor: this.state.RiskAnalysisStatusData?.Percentage > 0 && this.state.RiskAnalysisStatusData?.Percentage < 100 ? 'orange' : this.state.RiskAnalysisStatusData?.Percentage === 100 ? 'lime' : 'transparrent' }}>{this.state.RiskAnalysisStatusData?.Percentage > 0 ? `${this.state.RiskAnalysisStatusData?.Percentage}% - ${new Date(this.state.RiskAnalysisStatusData?.DateModified).toISOString().split("T")[0]}` : "NYS"}</small> <br />
                        <button onClick={() => this.setState({ SelectedItem: 1 })} className='btn risk-btn-main mt-1'>Analyse</button>
                      </div>
                      <div className='col-md-4'>
                        <small className="p-1" style={{ backgroundColor: this.state.RiskEvaluateStatusData?.Percentage > 0 && this.state.RiskEvaluateStatusData?.Percentage < 100 ? 'orange' : this.state.RiskEvaluateStatusData?.Percentage === 100 ? 'lime' : 'transparrent' }}>{this.state.RiskEvaluateStatusData?.Percentage > 0 ? `${this.state.RiskEvaluateStatusData?.Percentage}% - ${new Date(this.state.RiskEvaluateStatusData?.DateModified).toISOString().split("T")[0]}` : "NYS"}</small> <br />
                        <button onClick={() => this.handleUpdate(2, 0)} className='btn risk-btn-main mt-1'>Evaluate</button>
                      </div>
                      <div className='col-md-4'>
                        <small className="p-1" style={{ backgroundColor: this.state.RiskTreatStatusData?.Percentage > 0 && this.state.RiskTreatStatusData?.Percentage < 100 ? 'orange' : this.state.RiskTreatStatusData?.Percentage === 100 ? 'lime' : 'transparrent' }}>{this.state.RiskTreatStatusData?.Percentage > 0 ? `${this.state.RiskTreatStatusData?.Percentage}% - ${new Date(this.state.RiskTreatStatusData?.DateModified).toISOString().split("T")[0]}` : "NYS"}</small> <br />
                        <button onClick={() => this.setState({ SelectedItem: 3 })} className='btn risk-btn-main mt-1'>Treat</button>
                      </div>
                    </div>
                  </div>

                  <div className='mb-3 col-md vertical text-center'>
                    <strong>Assurances Management</strong>
                    <div className=''>
                      <small className="p-1" style={{ backgroundColor: this.state.RiskPlanStatusData?.Percentage > 0 && this.state.RiskPlanStatusData?.Percentage < 100 ? 'orange' : this.state.RiskPlanStatusData?.Percentage === 100 ? 'lime' : 'transparrent' }}>{this.state.RiskPlanStatusData?.Percentage > 0 ? `${this.state.RiskPlanStatusData?.Percentage}% - ${new Date(this.state.RiskPlanStatusData?.DateModified).toISOString().split("T")[0]}` : "NYS"}</small> <br />
                      <button onClick={() => this.setState({ SelectedItem: 4 })} className='btn risk-btn-main mt-1'>Plan</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.SelectedItem === 1 &&
              <>

                {this.state.RiskAnalysisStatusData.Percentage > 0 ?
                  <div className='d-flex'>
                    <div className='empty-div'></div>
                    <div className={this.state.RiskAnalysisStatusData.Percentage < 100 ? 'risk-update-section' : 'risk-update-section'}>
                      <h3 className='risk-text col-md-4'>Analyse the Risk: <em>{this.state.RiskAnalysisStatusData.Percentage}%  {this.state.RiskAnalysisStatusData.Percentage < 100 ? "Adequate" : "Completed"}</em></h3>
                      <div className='col-md-5'>
                        <small className=''>at {new Date(this.state.RiskAnalysisStatusData.DateModified).toISOString().split("T")[0]} <br /> {this.state.RiskAnalysisStatusData.UserName}</small>
                        <button onClick={() => this.editItem(this.state.AnalyseStatusId, "Analysis")} className='btn btn-info'><i className="far fa-edit me-2"></i>Update Status</button>
                      </div>
                    </div>
                    <div className='info-div text-end'>
                      <i className="fa-solid fa-circle-question" title={`What is this screen about?\n\n"Analyse the Risk" is all about ARTICULATING the Risk ... making sure that we have documented and categorised it fully.\nThis allows us to then measure it more accurately and to better create gap-closing actions that will reduce the risk.\n\nIt involves the following:\n1. Linking the risk to those aspects that the risk impacts. This means identifying which specific:\n   - Business Objectives;\n   - Business Processes;\n   - Capitals; &\n   - Stakeholders;\n2. Identifying all the root causes of the risk and listing these.\n3. Identifying all the consequences of the risk and listing these.\n4. Identifying all the existing controls that are in place. Each control may be linked to its associated root causes and\n   consequences (this is the classical bow-tie thinking).\n\nWhen the above steps have been completed, then you will have completed this phase.\n\nHow do I engage with this screen?\nClick on the various 'engage' buttons to take you to the screen that lets you link the various things and create/amend controls.\n\nPlease keep the phase completion status up to date...\n\nThe phase completion status is shown above the "Analyse the Risk" button (green bar for completed, orange bar for in progress and red bar for nys).\nThe date that the status was updated will be shown in that status bar.\nPlease update this status periodically - by clicking on the 'update' button.\n\nIn that way any user immediately sees the correct completion status at a glance.`}>
                      </i>
                    </div>
                  </div> :
                  <div className='d-flex'>
                    <div className='empty-div'></div>
                    <div className='risk-update-section'>
                      <h3 className='risk-text col-md-12 text-center'>Analyse the Risk: <em>Not Yet Started</em></h3>
                      <div className='col-md-5'>
                        <button onClick={() => this.editItem(0, "Analysis")} className='btn btn-info'><i className="far fa-edit me-2"></i>Update Status</button>
                      </div>
                    </div>
                    <div className='info-div text-end'>
                      <i className="fa-solid fa-circle-question" title={`What is this screen about?\n\n"Analyse the Risk" is all about ARTICULATING the Risk ... making sure that we have documented and categorised it fully.\nThis allows us to then measure it more accurately and to better create gap-closing actions that will reduce the risk.\n\nIt involves the following:\n1. Linking the risk to those aspects that the risk impacts. This means identifying which specific:\n   - Business Objectives;\n   - Business Processes;\n   - Capitals; &\n   - Stakeholders;\n2. Identifying all the root causes of the risk and listing these.\n3. Identifying all the consequences of the risk and listing these.\n4. Identifying all the existing controls that are in place. Each control may be linked to its associated root causes and\n   consequences (this is the classical bow-tie thinking).\n\nWhen the above steps have been completed, then you will have completed this phase.\n\nHow do I engage with this screen?\nClick on the various 'engage' buttons to take you to the screen that lets you link the various things and create/amend controls.\n\nPlease keep the phase completion status up to date...\n\nThe phase completion status is shown above the "Analyse the Risk" button (green bar for completed, orange bar for in progress and red bar for nys).\nThe date that the status was updated will be shown in that status bar.\nPlease update this status periodically - by clicking on the 'update' button.\n\nIn that way any user immediately sees the correct completion status at a glance.`}>
                      </i>
                    </div>
                  </div>}

                <div className='card mb-3'>
                  <div className='text-center'>
                  <h6 className='my-3 fw-bold'>Risk Categorisation</h6>
                  </div>
                  <div className='risk-container'>
                    <div className='box'>
                      <div className='text-center'><small>{this.state.RiskBusinessObjectiveStatusData.Percentage > 0 ? `${this.state.RiskBusinessObjectiveStatusData.Percentage}% - ${new Date(this.state.RiskBusinessObjectiveStatusData.DateModified).toISOString().split("T")[0]}` : "NYS"}</small></div>
                      <div className='card card1'>
                        <div className='card-header text-center d-flex justify-content-between align-items-center fw-bold'>Business Objectives <i className="fa-regular fa-square-plus" onClick={this.toggleObjectives} title='Add Business Objective'></i></div>
                        <div className='card-body risk-body'>
                          <small>(Click the <strong>"+"</strong> icon to add Business Objectives)</small>
                          <div className='d-flex flex-column justify-content-between'>
                            {this.state.RiskBusinessObjectiveData && this.state.RiskBusinessObjectiveData.map((item, index) => (
                              <div key={index} className='d-flex justify-content-between align-items-center'>
                                <span className=''>{item.BusinessObjectiveName}</span><i onClick={() => this.deleteItem(item.Id, 'api/riskbusinessobjectives/' + getUserDetails().UserRole)} className="fa-solid fa-trash-can text-danger"></i>
                              </div>
                            ))}
                          </div>
                          <div className='d-flex justify-content-center fw-bold'>
                            {this.state.RiskBusinessObjectiveData?.length ? <button onClick={() => this.editItem(this.state.BusinessObjectiveStatusId, "Objectives")} className='btn btn-info btn-sm'><i className="far fa-edit me-2"></i>Update Status</button> : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='box'>
                      <div className='text-center'><small>{this.state.RiskBusinessProcessStatusData.Percentage > 0 ? `${this.state.RiskBusinessProcessStatusData.Percentage}% - ${new Date(this.state.RiskBusinessProcessStatusData.DateModified).toISOString().split("T")[0]}` : "NYS"}</small></div>
                      <div className='card card1'>
                        <div className='card-header text-center d-flex justify-content-between align-items-center fw-bold'>Business Processes <i className="fa-regular fa-square-plus" onClick={this.toggleProcesses} title='Add Business Processes'></i></div>
                        <div className='card-body risk-body'>
                          <small>(Click the <strong>"+"</strong> icon to add Business Processes)</small>
                          <div className='d-flex flex-column justify-content-between'>
                            {this.state.RiskBusinessProcessData && this.state.RiskBusinessProcessData.map((item, index) => (
                              <div key={index} className='d-flex justify-content-between align-items-center'>
                                <span className=''>{item.BusinessProcessName}</span><i onClick={() => this.deleteItem(item.Id, 'api/riskbusinessprocesses/' + getUserDetails().UserRole)} className="fa-solid fa-trash-can text-danger"></i>
                              </div>
                            ))}
                          </div>
                          <div className='d-flex justify-content-center fw-bold'>
                            {this.state.RiskBusinessProcessData?.length ? <button onClick={() => this.editItem(this.state.BusinessProcessStatusId, "Processes")} className='btn btn-info btn-sm'><i className="far fa-edit me-2"></i>Update Status</button> : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='box'>
                      <div className='text-center'>
                        <small>{this.state.RiskCapitalStatusData.Percentage > 0 ? `${this.state.RiskCapitalStatusData.Percentage}% - ${new Date(this.state.RiskCapitalStatusData.DateModified).toISOString().split("T")[0]}` : "NYS"}</small>
                      </div>
                      <div className='card card1'>
                        <div className='card-header text-center d-flex justify-content-between align-items-center fw-bold'>Capitals <i className="fa-regular fa-square-plus" onClick={this.toggleCapitals} title='Add Capitals'></i></div>
                        <div className='card-body risk-body'>
                          <small>(Click the <strong>"+"</strong> icon to add Capitals)</small>
                          <div className='d-flex flex-column justify-content-between'>
                            {this.state.RiskCapitalData && this.state.RiskCapitalData.map((item, index) => (
                              <div key={index} className='d-flex justify-content-between align-items-start'>
                                <span className=''>{item.CapitalName}</span><i onClick={() => this.deleteItem(item.Id, 'api/riskcapitals/' + getUserDetails().UserRole)} className="fa-solid fa-trash-can text-danger"></i>
                              </div>
                            ))}
                          </div>
                          <div className='d-flex justify-content-center fw-bold'>
                            {this.state.RiskCapitalData?.length ? <button onClick={() => this.editItem(this.state.CapitalIdStatusId, "Capitals")} className='btn btn-info btn-sm'><i className="far fa-edit me-2"></i>Update Status</button> : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='box'>
                      <div className='text-center'>
                        <small>{this.state.RiskStakeholderStatusData.Percentage > 0 ? `${this.state.RiskStakeholderStatusData.Percentage}% - ${new Date(this.state.RiskStakeholderStatusData.DateModified).toISOString().split("T")[0]}` : "NYS"}</small>
                      </div>
                      <div className='card card1'>
                        <div className='card-header text-center d-flex justify-content-between align-items-center fw-bold'>Stakeholders <i className="fa-regular fa-square-plus" onClick={this.toggleStakeholders} title='Add Stakeholders'></i></div>
                        <div className='card-body risk-body'>
                          <small>(Click the <strong>"+"</strong> icon to add Stakeholders)</small>
                          <div className='d-flex flex-column justify-content-between'>
                            {this.state.RiskStakeholderData && this.state.RiskStakeholderData.map((item, index) => (
                              <div key={index} className='d-flex justify-content-between align-items-center'>
                                <span className=''>{item.StakeholderName}</span><i onClick={() => this.deleteItem(item.Id, 'api/riskstakeholders/' + getUserDetails().UserRole)} className="fa-solid fa-trash-can text-danger"></i>
                              </div>
                            ))}
                          </div>
                          <div className='d-flex justify-content-center fw-bold'>
                            {this.state.RiskStakeholderData?.length ? <button onClick={() => this.editItem(this.state.StakeholdersStatusId, "Stakeholders")} className='btn btn-info btn-sm'><i className="far fa-edit me-2"></i>Update Status</button> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card mb-3'>
                  <div className='text-center'>
                    <h6 className='my-3 fw-bold'>Risk Decomposition</h6>
                  </div>
                  <div className='risk-container gap-4'>
                    <div style={{ flex: '1' }}>
                      <div className='text-center'>
                        <small>{this.state.RootCauseStatusData.Percentage > 0 ? `${this.state.RootCauseStatusData.Percentage}% - ${new Date(this.state.RootCauseStatusData.DateModified).toISOString().split("T")[0]}` : "NYS"}</small>
                      </div>
                      <div className='card card1'>
                        <div className='card-header text-center d-flex justify-content-between align-items-center fw-bold'>Root Causes <i className="fa-regular fa-square-plus" onClick={this.toggleRootCauses} title='Add Root Cause'></i></div>
                        <div className='card-body risk-body'>
                          <div>
                            <small>(Click the <strong>"+"</strong> icon to add a Root Cause)</small>
                            {this.state.RootCausesList && this.state.RootCausesList.map((item, index) => (
                              <div key={index} className='d-flex justify-content-between align-items-center'>
                                <span className=''>
                                  {this.state.ControlsList.find(x => x.ConnectedRootCauses.includes(item.Id)) ? (
                                    <span style={{ display: "inline-block", width: "28px" }}>&nbsp;</span>
                                  ) : <i className="fa-solid fa-link-slash text-danger me-2" title="This cause has not yet been linked to any control"></i>}
                                  {item.ShortName}
                                </span>
                                <i onClick={() => this.deleteItem(item.Id, 'api/rootcauses/' + getUserDetails().UserRole)} className="fa-solid fa-trash-can text-danger"></i>
                              </div>
                            ))}
                          </div>
                          <div className='d-flex justify-content-center fw-bold'>
                            {this.state.RootCausesList?.length ? <button onClick={() => this.editItem(0, "Root")} className='btn btn-info btn-sm'><i className="far fa-edit me-2"></i>Update Status</button> : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ flex: '2' }}>
                      <div className='text-center'>
                        <small>{this.state.ControlStatusData.Percentage > 0 ? `${this.state.ControlStatusData.Percentage}% - ${new Date(this.state.ControlStatusData.DateModified).toISOString().split("T")[0]}` : "NYS"}</small>
                      </div>
                      <div className='card card1'>
                        <div className='card-header text-center d-flex justify-content-between align-items-center fw-bold'>Controls <i className={this.state.ControlsList?.length > 0 ? "fa-regular fa-eye" : "fa-regular fa-square-plus"} onClick={() => { this.state.ControlsList?.length > 0 ? this.toggleControls() : this.engageItem(0) }} title='View/Add Controls'></i></div>
                        <div className='card-body risk-body'>
                          <div>
                            <small>(Click a Control to see related root causes & consequences)</small>
                            <div className="table-responsive">
                              <Table striped hover>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th className="fw-bold">Short Name</th>
                                    <th className="fw-bold"><i className="fa-regular fa-newspaper text-primary"></i></th>
                                    <th className="fw-bold"><i className="fa-solid fa-paperclip text-primary"></i></th>
                                    <th className="fw-bold">Critical?</th>
                                    <th className="fw-bold">Needs Assurence?</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.ControlsList && this.state.ControlsList.map((item, index) => (
                                    <tr key={index}>
                                      <td className="ps-2 pe-0">{!item.ConnectedConsequences.length && !item.ConnectedRootCauses.length ? (<i className="fa-solid fa-link-slash text-danger" title="This consequence has not yet been linked to any control"></i>) : null}</td>
                                      <td>{item.ShortName}</td>
                                      <td>{item.FindingCount}</td>
                                      <td>{item.AttachmentCount}</td>
                                      <td>{item.Critical === false && item.NeedsAssurance ?
                                        <div style={{ width: "20px", height: "20px", backgroundColor: "orange" }}></div> : item.Critical ?
                                          <div style={{ width: "20px", height: "20px", border: "1px grey solid", display: "grid", placeContent: "center" }}><i className="fa-solid fa-check fs-6"></i></div> :
                                          <div style={{ width: "20px", height: "20px", border: "1px grey solid" }}></div>
                                      }</td>
                                      <td>{item.NeedsAssurance === false && item.Critical ?
                                        <div style={{ width: "20px", height: "20px", backgroundColor: "orange" }}></div> : item.NeedsAssurance ?
                                          <div style={{ width: "20px", height: "20px", border: "1px grey solid", display: "grid", placeContent: "center" }}><i className="fa-solid fa-check fs-6"></i></div> :
                                          <div style={{ width: "20px", height: "20px", border: "1px grey solid" }}></div>
                                      }</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          <div className='d-flex justify-content-center fw-bold'>
                            {this.state.ControlsList?.length ? <button onClick={() => this.editItem(0, "Controls")} className='btn btn-info btn-sm'><i className="far fa-edit me-2"></i>Update Status</button> : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ flex: '1' }}>
                      <div className='text-center'>
                        <small>{this.state.ConsequenceStatusData.Percentage > 0 ? `${this.state.ConsequenceStatusData.Percentage}% - ${new Date(this.state.ConsequenceStatusData.DateModified).toISOString().split("T")[0]}` : "NYS"}</small>
                      </div>
                      <div className='card card1'>
                        <div className='card-header text-center d-flex justify-content-between align-items-center fw-bold'>Consequences <i className="fa-regular fa-square-plus" onClick={this.toggleConsequences} title='Add Consequence'></i></div>
                        <div className='card-body risk-body'>
                          <div>
                            <small>(Click the <strong>"+"</strong> icon to add a Consequence)</small>
                            {this.state.ConsequencesList && this.state.ConsequencesList.map((item, index) => (
                              <div key={index} className='d-flex justify-content-between align-items-center'>
                                <span className=''>
                                  {this.state.ControlsList.find(x => x.ConnectedConsequences.includes(item.Id)) ? (
                                    <span style={{ display: "inline-block", width: "28px" }}>&nbsp;</span>
                                  ) : <i className="fa-solid fa-link-slash text-danger me-2" title="This consequence has not been linked to any control yet"></i>}
                                  {item.ShortName}
                                </span>
                                <i onClick={() => this.deleteItem(item.Id, 'api/consequences/' + getUserDetails().UserRole)} className="fa-solid fa-trash-can text-danger"></i>
                              </div>
                            ))}
                          </div>
                          <div className='d-flex justify-content-center fw-bold'>
                            {this.state.ConsequencesList?.length ? <button onClick={() => this.editItem(0, "Consequences")} className='btn btn-info btn-sm'><i className="far fa-edit me-2"></i>Update Status</button> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </>}
            {this.state.SelectedItem === 2 &&
              <RiskEvaluate RiskId={this.state.Id} CurrentView={this.state.CurrentView} Load={this.loadData} RootCauses={this.state.RootCausesList} Consequences={this.state.ConsequencesList} Controls={this.state.ControlsList} RiskStatuses={this.state.RiskStatusList} SiteId={this.state.SiteStructureId} />
            }

            {this.state.SelectedItem === 3 &&
              <RiskTreat RiskId={this.state.Id} Load={this.loadData} UsersList={this.state.UsersList} />
            }

            {this.state.SelectedItem === 4 &&
              <RiskPlan RiskId={this.state.Id} Load={this.loadData} UsersList={this.state.UsersList} Controls={this.state.ControlsList} />
            }
          </div>

          <Modal isOpen={this.state.objectivesModal} toggle={this.toggleObjectives} className={this.props.className} scrollable size="md" backdrop="static">
            <ModalHeader toggle={this.toggleObjectives} close={<button className="close" onClick={this.toggleObjectives}><i className="fas fa-times"></i></button>}>Business Objectives</ModalHeader>
            <ModalBody>
              <div className='row'>
                <div className='mb-3'>
                  <DropDownListComponent id='ddBusinessObjectiveId' name='ddBusinessObjectiveId' placeholder='Business Objective' dataSource={Objectives.length > 0 ? [{ Title: "-SELECT-", Id: 0 }, ...Objectives] : Objectives} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.BusinessObjectiveId} change={e => this.setState({ BusinessObjectiveId: e.value })} /><div id='tbBusinessObjectiveIdError' className='error-message' />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="dark" size="sm" onClick={this.toggleObjectives}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
              <Button color="success" size="sm" onClick={() => this.saveRiskBusinessObjective()}>Save <i className="far fa-check-circle ms-2"></i></Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.processesModal} toggle={this.toggleProcesses} className={this.props.className} scrollable size="md" backdrop="static">
            <ModalHeader toggle={this.toggleProcesses} close={<button className="close" onClick={this.toggleProcesses}><i className="fas fa-times"></i></button>}>Business Processes</ModalHeader>
            <ModalBody>
              <div className='row'>
                <div className='mb-3'>
                  <DropDownListComponent id='ddBusinessProcessId' name='ddBusinessProcessId' placeholder='Business Processes' dataSource={Processes.length > 0 ? [{ ShortName: "-SELECT-", Id: 0 }, ...Processes] : Processes} fields={{ text: 'ShortName', value: 'Id' }} floatLabelType='Always' value={this.state.BusinessProcessId} change={e => this.setState({ BusinessProcessId: e.value })} /><div id='tbBusinessProcessIdError' className='error-message' />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="dark" size="sm" onClick={this.toggleProcesses}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
              <Button color="success" size="sm" onClick={() => this.saveRiskBusinessProcess()}>Save <i className="far fa-check-circle ms-2"></i></Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.capitalsModal} toggle={this.toggleCapitals} className={this.props.className} scrollable size="md" backdrop="static">
            <ModalHeader toggle={this.toggleCapitals} close={<button className="close" onClick={this.toggleCapitals}><i className="fas fa-times"></i></button>}>Capitals</ModalHeader>
            <ModalBody>
              <div className='row'>
                <div className='mb-3'>
                  <DropDownListComponent id='ddCapitalId' name='ddCapitalId' placeholder='Capitals' dataSource={Capitals.length > 0 ? [{ Title: "-SELECT-", Id: 0 }, ...Capitals] : Capitals} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.CapitalId} change={e => this.setState({ CapitalId: e.value })} /><div id='tbCapitalIdError' className='error-message' />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="dark" size="sm" onClick={this.toggleCapitals}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
              <Button color="success" size="sm" onClick={() => this.saveRiskCapitals()}>Save <i className="far fa-check-circle ms-2"></i></Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.stakeholdersModal} toggle={this.toggleStakeholders} className={this.props.className} scrollable size="md" backdrop="static">
            <ModalHeader toggle={this.toggleStakeholders} close={<button className="close" onClick={this.toggleStakeholders}><i className="fas fa-times"></i></button>}>Stakeholders</ModalHeader>
            <ModalBody>
              <div className='row'>
                <div className='mb-3'>
                  <DropDownListComponent id='ddStakeholderId' name='ddStakeholderId' placeholder='Stakeholders' dataSource={Stakeholders.length > 0 ? [{ Title: "-SELECT-", Id: 0 }, ...Stakeholders] : Stakeholders} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.StakeholderId} change={e => this.setState({ StakeholderId: e.value })} /><div id='tbStakeholderIdError' className='error-message' />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="dark" size="sm" onClick={this.toggleStakeholders}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
              <Button color="success" size="sm" onClick={() => this.saveRiskStakeholders()}>Save <i className="far fa-check-circle ms-2"></i></Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.rootCausesModal} toggle={this.toggleRootCauses} className={this.props.className} scrollable size="md" backdrop="static">
            <ModalHeader toggle={this.toggleRootCauses} close={<button className="close" onClick={this.toggleRootCauses}><i className="fas fa-times"></i></button>}>Root Causes</ModalHeader>
            <ModalBody>
              <div className='row'>
                <div className='mb-3 col-md-12'>
                  <TextBoxComponent id='tbShortName' name='tbShortName' placeholder='Short Name' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.ShortName} onChange={e => this.setState({ ShortName: e.target.value })} />
                </div>

                <div className='mb-3 col-md-12'>
                  <TextBoxComponent multiline id='tbRiskDescription' name='tbRiskDescription' placeholder='Description' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.RiskDescription} onChange={e => this.setState({ RiskDescription: e.target.value })} />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="dark" size="sm" onClick={this.toggleRootCauses}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
              <Button color="success" size="sm" onClick={() => this.saveRootCauses()}>Save <i className="far fa-check-circle ms-2"></i></Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.controlsModal} toggle={this.toggleControls} className={this.props.className} scrollable size="xl" backdrop="static">
            <ModalHeader toggle={this.toggleControls} close={<button className="close" onClick={this.toggleControls}><i className="fas fa-times"></i></button>}>Controls Against This Risk</ModalHeader>
            <ModalBody>
              <div className='text-center'>
                <em className='my-3 fw-bold'>(Add new control or click on 'edit' to do more with an existing control)</em>
              </div>
              {this.state.ControlsList &&
                this.renderDataTable(this.state.ControlsList, this.state.controlGridCommands, this.state.gridToolbar, this.onGridCommand)
              }
            </ModalBody>
            <ModalFooter>
              <Button color="dark" size="sm" onClick={this.toggleControls}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
              <Button color="primary" size="sm" onClick={() => this.engageItem(0)}>Add Control <i className="fa-regular fa-square-plus ms-2"></i></Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.controlsEditModal} toggle={this.toggleControlsEdit} className={this.props.className} scrollable size="lg" backdrop="static">
            <ModalHeader toggle={this.toggleControlsEdit} close={<button className="close" onClick={this.toggleControlsEdit}><i className="fas fa-times"></i></button>}>Controls</ModalHeader>
            <ModalBody>
              <div className='text-center'>
                <em className='my-3 fw-bold'>(Add new control or click on 'edit' to do more with an existing control)</em>
              </div>
              <div className='row'>
                {this.state.editId > 0 && <div className='d-flex risk-status mb-3'>
                  <Attachments Id={this.state.editId} Type={2} />
                  <Findings Id={this.state.editId} Type={2} />
                </div>}
                <div className='mb-3 col-md-12'>
                  <TextBoxComponent id='tbShortName' name='tbShortName' placeholder='Short Name' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.ShortName} onChange={e => this.setState({ ShortName: e.target.value })} />
                </div>

                <div className='mb-3 col-md-12'>
                  <TextBoxComponent multiline id='tbRiskDescription' name='tbRiskDescription' placeholder='Description' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.RiskDescription} onChange={e => this.setState({ RiskDescription: e.target.value })} />
                </div>

                <div className='mb-3 col-md-4'>
                  <DropDownListComponent id='ddControlResponsiblePersonId' name='ddControlResponsiblePersonId' placeholder='Responsible Person (Control)' dataSource={[{ FullName: "-SELECT-", Id: 0 }, ...this.state.UsersList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={this.state.ControlResponsiblePersonId} change={e => this.setState({ ControlResponsiblePersonId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
                </div>
                <div className='col-md-4 align-self-center'>
                  <CheckBoxComponent id='cbCritical' name='cbCritical' label='Critical' checked={this.state.Critical} change={e => this.setState({ Critical: e.checked })} />
                </div>
                <div className='col-md-4 align-self-center'>
                  <CheckBoxComponent id='cbNeedsAssurance' name='cbNeedsAssurance' label='Needs Assurance' checked={this.state.NeedsAssurance} change={e => this.setState({ NeedsAssurance: e.checked })} />
                </div>
              </div>
              <div className='text-center'>
                <h6 className='my-3 fw-bold'>Control Classification</h6>
              </div>
              <div className='row'>
                <div className='mb-3 col-md-4'>
                  <DropDownListComponent id='ddLevelId' name='ddLevelId' placeholder='Level' dataSource={levels} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.LevelId} change={e => this.setState({ LevelId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
                </div>
                <div className='mb-3 col-md-4'>
                  <DropDownListComponent id='ddITId' name='ddITId' placeholder='IT/Non-IT' dataSource={isIT} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.ITId} change={e => this.setState({ ITId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
                </div>
                <div className='mb-3 col-md-4'>
                  <DropDownListComponent id='ddAutoId' name='ddAutoId' placeholder='Automated/Manual' dataSource={isAutomated} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.AutomatedId} change={e => this.setState({ AutomatedId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
                </div>
              </div>
            </ModalBody>
            <ModalFooter className='d-flex justify-content-between'>
              {this.state.editId > 0 && <div>
                <Button className='me-1' color="info" size="sm" onClick={() => { this.toggleControlRoot() }}><i className="fas fa-tasks me-2"></i>Select Root Causes</Button>
                <Button className='me-1' color="info" size="sm" onClick={() => { this.toggleControlConsequence() }}><i className="fas fa-tasks me-2"></i>Select Consequences</Button>
              </div>}
              {this.state?.Assurances?.length > 0 && <div>
                <Button color="primary" size="sm" onClick={() => { this.setState({ SelectedItem: 4 }); this.toggleControlsEdit(); }}>Engage with Control Assurance Plan</Button>
              </div>}
              <div className='ms-auto'>
                <Button className='ms-1' color="dark" size="sm" onClick={() => { this.toggleControlsEdit(); this.toggleControls(); }}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                <Button className='ms-1' color="success" size="sm" onClick={() => this.saveControls()}>Save <i className="far fa-check-circle ms-2"></i></Button>
              </div>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.controlRootModal} toggle={this.toggleControlRoot} className={this.props.className} scrollable size="sm" backdrop="static">
            <ModalHeader toggle={this.toggleControlRoot} close={<button className="close" onClick={this.toggleControlRoot}><i className="fas fa-times"></i></button>}>Control Root Causes</ModalHeader>
            <ModalBody>
              <div className='row'>
                <small className='text-center'>(Root cause/s addressed by this control)</small>
                {this.state.RootCausesList.length ? this.state.RootCausesList.map((cause, index) => (
                  <div className='mb-2' key={index}>
                    <CheckBoxComponent
                      id={`cbRootCause_${cause.Id}`}
                      name={`cbRootCause_${cause.Id}`}
                      type="checkbox"
                      label={cause.ShortName}
                      checked={this.state.ConnectedRootCauses?.includes(cause.Id)}
                      change={() => this.handleRootCauseChange(cause.Id)}
                    />
                  </div>
                )) : <span className='text-center'>Create a Root Cause first to link to a control</span>}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="dark" size="sm" onClick={this.toggleControlRoot}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
              {this.state.RootCausesList.length ? <Button color="success" size="sm" onClick={() => this.saveControls()}>Save <i className="far fa-check-circle ms-2"></i></Button> : ""}
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.controlConsequenceModal} toggle={this.toggleControlConsequence} className={this.props.className} scrollable size="sm" backdrop="static">
            <ModalHeader toggle={this.toggleControlConsequence} close={<button className="close" onClick={this.toggleControlConsequence}><i className="fas fa-times"></i></button>}>Control Consequences</ModalHeader>
            <ModalBody>
              <div className='row'>
                <small className='text-center'>(Consequence/s addressed by this control)</small>
                {this.state.ConsequencesList.length ? this.state.ConsequencesList.map((consequence, index) => (
                  <div className='mb-2' key={index}>
                    <CheckBoxComponent
                      id={`cbConsequence_${consequence.Id}`}
                      name={`cbConsequence_${consequence.Id}`}
                      type="checkbox"
                      label={consequence.ShortName}
                      checked={this.state.ConnectedConsequences?.includes(consequence.Id)}
                      change={() => this.handleConsequenceChange(consequence.Id)}
                    />
                  </div>
                )) : <span className='text-center'>Create a Consequence first to link to a control</span>}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="dark" size="sm" onClick={this.toggleControlConsequence}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
              {this.state.ConsequencesList.length ? <Button color="success" size="sm" onClick={() => this.saveControls()}>Save <i className="far fa-check-circle ms-2"></i></Button> : ""}
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.consequencesModal} toggle={this.toggleConsequences} className={this.props.className} scrollable size="md" backdrop="static">
            <ModalHeader toggle={this.toggleConsequences} close={<button className="close" onClick={() => { this.toggleConsequences(); this.setState({ ConnectedPerspectives: [] }); }}><i className="fas fa-times"></i></button>}>Consequences</ModalHeader>
            <ModalBody>
              <div className='row'>
                <div className='mb-3 col-md-12'>
                  <TextBoxComponent id='tbShortName' name='tbShortName' placeholder='Short Name' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.ShortName} onChange={e => this.setState({ ShortName: e.target.value })} />
                </div>

                <div className='mb-3 col-md-12'>
                  <TextBoxComponent multiline id='tbRiskDescription' name='tbRiskDescription' placeholder='Description' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.RiskDescription} onChange={e => this.setState({ RiskDescription: e.target.value })} />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="info" size="sm" onClick={() => { this.toggleConsequences(); this.togglePerspectiveConsequence(); }}><i className="far fa-check-square me-2"></i>Manage Consequences</Button>
              <Button color="dark" size="sm" onClick={() => { this.toggleConsequences(); this.setState({ ConnectedPerspectives: [] }); }}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
              <Button color="success" size="sm" onClick={() => this.saveConsequences()}>Save <i className="far fa-check-circle ms-2"></i></Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.perspectiveConsequenceModal} toggle={this.togglePerspectiveConsequence} className={this.props.className} scrollable size="md" backdrop="static">
            <ModalHeader toggle={this.togglePerspectiveConsequence} close={<button className="close" onClick={this.togglePerspectiveConsequence}><i className="fas fa-times"></i></button>}>Applicable Rating Perspective</ModalHeader>
            <ModalBody>
              <div className='row'>
                {this.state.PerspectivesList.length ? this.state.PerspectivesList.map((perspective, index) => (
                  <div className='mb-2' key={index}>
                    <CheckBoxComponent
                      id={`cbConsequence_${perspective.Id}`}
                      name={`cbConsequence_${perspective.Id}`}
                      type="checkbox"
                      label={perspective.Title}
                      checked={this.state.ConnectedPerspectives?.includes(perspective.Id)}
                      change={() => this.handlePerspectiveChange(perspective.Id)}
                    />
                  </div>
                )) : <span className='text-center'>Create perspectives first to link to a Consequence</span>}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="dark" size="sm" onClick={() => { this.togglePerspectiveConsequence(); this.toggleConsequences(); }}>Back <i className="far fa-times-circle ms-2"></i></Button>
              {/*{this.state.PerspectivesList.length ? <Button color="success" size="sm" onClick={() => this.saveConsequences()}>Save <i className="far fa-check-circle ms-2"></i></Button> : ""}*/}
            </ModalFooter>
          </Modal>

          <EngagementModal key="mdlRiskAnalysis" ref={this.analysisStatusRef} Title="Analyse The Risk" SaveFunction={this.saveEngagement} Data={{ Id: this.state.RiskAnalysisStatusData?.RiskId, PreviousPercentage: this.state.PreviousPercentage, UserName: this.state.UserName, PreviousDate: this.state.PreviousDate, PreviousComment: this.state.PreviousComment, Route: 'api/riskanalysisstatus/' + getUserDetails().UserRole }} />
          <EngagementModal key="mdlObjectivesStatus" ref={this.objectivesStatusRef} Title="Business Objective Status" SaveFunction={this.saveEngagement} Data={{ Id: this.state.RiskBusinessObjectiveStatusData?.RiskId, PreviousPercentage: this.state.PreviousPercentage, UserName: this.state.UserName, PreviousDate: this.state.PreviousDate, PreviousComment: this.state.PreviousComment, Route: 'api/riskbusinessobjectivestatus/' + getUserDetails().UserRole }} />
          <EngagementModal key="mdlProcessesStatus" ref={this.processesStatusRef} Title="Business Process Status" SaveFunction={this.saveEngagement} Data={{ Id: this.state.RiskBusinessProcessStatusData?.RiskId, PreviousPercentage: this.state.PreviousPercentage, UserName: this.state.UserName, PreviousDate: this.state.PreviousDate, PreviousComment: this.state.PreviousComment, Route: 'api/riskbusinessprocessstatus/' + getUserDetails().UserRole }} />
          <EngagementModal key="mdlCapitalsStatus" ref={this.capitalStatusRef} Title="Capital Status" SaveFunction={this.saveEngagement} Data={{ Id: this.state.RiskCapitalStatusData?.RiskId, PreviousPercentage: this.state.PreviousPercentage, UserName: this.state.UserName, PreviousDate: this.state.PreviousDate, PreviousComment: this.state.PreviousComment, Route: 'api/riskcapitalsstatus/' + getUserDetails().UserRole }} />
          <EngagementModal key="mdlStakeholdersStatus" ref={this.stakeholderStatusRef} Title="Stakeholder Status" SaveFunction={this.saveEngagement} Data={{ Id: this.state.RiskStakeholderStatusData?.RiskId, PreviousPercentage: this.state.PreviousPercentage, UserName: this.state.UserName, PreviousDate: this.state.PreviousDate, PreviousComment: this.state.PreviousComment, Route: 'api/riskstakeholdersstatus/' + getUserDetails().UserRole }} />
          <EngagementModal key="mdlRootCauseStatus" ref={this.rootCausesStatusRef} Title="Root Causes Status" SaveFunction={this.saveEngagement} Data={{ Id: this.state.RootCauseStatusData?.RiskId, PreviousPercentage: this.state.PreviousPercentage, UserName: this.state.UserName, PreviousDate: this.state.PreviousDate, PreviousComment: this.state.PreviousComment, Route: 'api/rootcausestatus/' + getUserDetails().UserRole }} />
          <EngagementModal key="mdlControlStatus" ref={this.controlsStatusRef} Title="Control Status" SaveFunction={this.saveEngagement} Data={{ Id: this.state.ControlStatusData?.RiskId, PreviousPercentage: this.state.PreviousPercentage, UserName: this.state.UserName, PreviousDate: this.state.PreviousDate, PreviousComment: this.state.PreviousComment, Route: 'api/controlstatus/' + getUserDetails().UserRole }} />
          <EngagementModal key="mdlConsequenceStatus" ref={this.consequencesStatusRef} Title="Consequence Status" SaveFunction={this.saveEngagement} Data={{ Id: this.state.ConsequenceStatusData?.RiskId, PreviousPercentage: this.state.PreviousPercentage, UserName: this.state.UserName, PreviousDate: this.state.PreviousDate, PreviousComment: this.state.PreviousComment, Route: 'api/consequencestatus/' + getUserDetails().UserRole }} />

        </div>
      </>
    );
  }

  async loadData() {
    var bearer = 'Bearer ' + getAuthToken();
    const { handleNavigate } = this.context;
    const parts = window.location.href.split("/");
    const riskNumber = parts[parts.length - 1];
    const userData = getUserDetails();

    try {
      const response = await fetch(`api/risks/getbyriskid/${riskNumber}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        

        this.setState({ Id: data.Id, RiskAnalysisStatusId: data.Id, ResponsiblePersonName: data.ResponsiblePersonName, ResponsibleTeamName: data.ResponsibleTeamName, AddedByName: data.AddedByName, AddedByTeamName: data.AddedByTeamName, CompanyId: data.CompanyId, RiskNumber: data.RiskNumber, Name: data.Name, Description: data.Description, RiskTypeId: data.RiskTypeId, RiskCategoryId: data.RiskCategoryId, RiskStatusId: data.RiskStatusId, SiteStructureId: data.SiteStructureId, SpeedOfOnset: data.SpeedOfOnset, ResponsiblePersonId: data.ResponsiblePersonId, ResponsibleTeamId: data.ResponsibleTeamId, HasOpportunities: data.HasOpportunities, Opportunities: data.Opportunities, HasIndicators: data.HasIndicators, Indicators: data.Indicators, DeactivationReason: data.DeactivationReason, DateAdded: data.DateAdded, AddedBy: data.AddedBy, AddedByTeamId: data.AddedByTeamId, DateModified: data.DateModified, ModifiedBy: data.ModifiedBy, Status: data.Status, RiskBusinessObjectiveData: data.RiskBusinessObjectiveData ? JSON.parse(data.RiskBusinessObjectiveData) : null, RiskBusinessProcessData: data.RiskBusinessProcessData ? JSON.parse(data.RiskBusinessProcessData) : null, RiskCapitalData: data.RiskCapitalData ? JSON.parse(data.RiskCapitalData) : null, RiskStakeholderData: data.RiskStakeholderData ? JSON.parse(data.RiskStakeholderData) : null, });
        //this.setState({ editData: data, loading: false });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/userdata/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({
          UsersList: data.map(item => ({
            ...item,
            FullName: item.FirstName + " " + item.LastName
          }))
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/evaluationperspectives/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ PerspectivesList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/businessobjectives/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ BusinessObjectivesList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/businessprocesses/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ BusinessProcessesList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/capitals/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ CapitalsList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/stakeholders/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ StakeholdersList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/risktypes/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskTypeList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskcategories/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskCategoryList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskstatuses/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskStatusList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/sitestructures/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ SiteStructureList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401) {
          handleNavigate("/");
        }

      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskevaluation/GetRiskEvaluations/${userData.CompanyId}/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data)
        this.setState({ PotentialActualTargetData: data });
      } else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }
    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/rootcauses/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ RootCausesList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/controls/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        //console.log(data);
        data = data.map(item => ({ ...item, Assurances: JSON.parse(item.Assurances), ConnectedRootCauses: item.ConnectedRootCauses ? JSON.parse(item.ConnectedRootCauses) : [], ConnectedConsequences: item.ConnectedConsequences ? JSON.parse(item.ConnectedConsequences) : [] }))
        //console.log(data);
        this.setState({ ControlsList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/consequences/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        //console.log(data)
        data = data.map(item => ({ ...item, ConnectedPerspectives: item.ConnectedPerspectives ? JSON.parse(item.ConnectedPerspectives) : [] }));
        this.setState({ ConsequencesList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskbusinessobjectivestatus/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskBusinessObjectiveStatusData: data, BusinessObjectiveStatusId: data.Id });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskbusinessprocessstatus/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskBusinessProcessStatusData: data, BusinessProcessStatusId: data.Id });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskcapitalsstatus/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskCapitalStatusData: data, CapitalIdStatusId: data.Id });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskstakeholdersstatus/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskStakeholderStatusData: data, StakeholdersStatusId: data.Id });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/rootcausestatus/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RootCauseStatusData: data, RootCauseStatusId: data.Id });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/controlstatus/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ ControlStatusData: data, ControlStatusId: data.Id });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/consequencestatus/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ ConsequenceStatusData: data, ConsequenceStatusId: data.Id });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskevaluatestatus/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ RiskEvaluateStatusData: data, RiskEvaluateStatusId: data.Id });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/risktreatstatus/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskTreatStatusData: data, RiskTreatStatusId: data.Id });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskplanstatus/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ RiskPlanStatusData: data, RiskPlanStatusId: data.Id });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskanalysisstatus/getbyriskid/${this.state.RiskAnalysisStatusId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskAnalysisStatusData: data, AnalyseStatusId: data.Id, loading: false });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }


    setAuthToken(getAuthToken(), new Date());
  }

  saveEngagement = async (percentage, comment, route) => {
    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: 0, RiskId: this.state.Id, Percentage: percentage, Note: comment }
    console.log(data);
    try {
      const response = await fetch(route, {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.setState({ Percentage: 0, Comment: "", PreviousPercentage: 0, UserName: "", PreviousDate: null, PreviousComment: "" });
        this.loadData();
        ToastUtility.show({
          title: 'Risk Status', content: 'The risk status was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Status', content: 'There was an error saving the risk status!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveRiskAnalysisStatus(dataId, route) {
    console.log(dataId);
    console.log(route);

    const { handleNavigate } = this.context;
    const userData = getUserDetails();
    this.setState({
      loading: true,
      showError: false,
      showSuccess: false,
      riskAnalysisModal: false,
      stakeholdersStatusModal: false,
      objectivesStatusModal: false,
      processesStatusModal: false,
      capitalsStatusModal: false,
      rootCauseStatusModal: false,
      controlStatusModal: false,
      consequenceStatusModal: false,
    });
    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: dataId, RiskId: this.state.Id, Percentage: this.state.Percentage, Note: this.state.Comment, DateAdded: new Date(), AddedBy: userData.Id, DateModified: new Date(), ModifiedBy: userData.Id, Status: this.state.Status, }
    console.log(data);
    try {
      const response = await fetch(route, {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.setState({ Percentage: 0, Comment: "", PreviousPercentage: 0, UserName: "", PreviousDate: null, PreviousComment: "" });
        this.loadData();
        ToastUtility.show({
          title: 'Risk Status', content: 'The risk status was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Status', content: 'There was an error saving the risk status!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveRiskBusinessObjective() {
    const { handleNavigate } = this.context;
    if (this.state.BusinessObjectiveId) {
      this.setState({ loading: true, showError: false, showSuccess: false, objectivesModal: false });
      const userData = getUserDetails();

      var bearer = 'Bearer ' + getAuthToken();
      var data = { Id: 0, RiskId: this.state.Id, BusinessObjectiveId: this.state.BusinessObjectiveId, DateAdded: new Date(), AddedBy: userData.Id, DateModified: new Date(), ModifiedBy: userData.Id, Status: 1, }

      try {
        const response = await fetch('api/riskbusinessobjectives/' + userData.UserRole, {
          method: 'POST',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          await response.json();
          this.setState({ BusinessObjectiveId: 0 });
          this.loadData();
          ToastUtility.show({
            title: 'Risk Business Objectives', content: 'The business objectives was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
          });
        }
        else {
          console.error(response.status + ": " + response.statusText);
          if (response.status === 401)
            handleNavigate("/");
        }

      } catch (e) {
        console.error(e);
        this.setState({ loading: false });
        ToastUtility.show({
          title: 'Risk Business Objectives', content: 'There was an error saving the business objectives!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
    } else {
      ToastUtility.show({
        title: 'Risk Business Objectives', content: 'Create new Business Objectives first!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveRiskBusinessProcess() {
    const { handleNavigate } = this.context;
    this.setState({ loading: true, showError: false, showSuccess: false, processesModal: false });
    const userData = getUserDetails();

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: 0, RiskId: this.state.Id, BusinessProcessId: this.state.BusinessProcessId, DateAdded: new Date(), AddedBy: userData.Id, DateModified: new Date(), ModifiedBy: userData.Id, Status: 1, }

    try {
      const response = await fetch('api/riskbusinessprocesses/' + userData.UserRole, {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.setState({ BusinessProcessId: 0 });
        this.loadData();
        ToastUtility.show({
          title: 'Risk Business Processes', content: 'The business processes was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Business Processes', content: 'There was an error saving the business processes!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveRiskCapitals() {
    const { handleNavigate } = this.context;
    const userData = getUserDetails();
    this.setState({ loading: true, showError: false, showSuccess: false, capitalsModal: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: 0, RiskId: this.state.Id, CapitalsId: this.state.CapitalId, DateAdded: new Date(), AddedBy: userData.Id, DateModified: new Date(), ModifiedBy: userData.Id, Status: 1, }

    try {
      const response = await fetch('api/riskcapitals/' + userData.UserRole, {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.setState({ CapitalId: 0 });
        this.loadData();
        ToastUtility.show({
          title: 'Risk Capitals', content: 'The capitals was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Capitals', content: 'There was an error saving the capitals!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveRiskStakeholders() {
    const { handleNavigate } = this.context;
    const userData = getUserDetails();
    this.setState({ loading: true, showError: false, showSuccess: false, stakeholdersModal: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: 0, RiskId: this.state.Id, StakeholderId: this.state.StakeholderId, DateAdded: new Date(), AddedBy: userData.Id, DateModified: new Date(), ModifiedBy: userData.Id, Status: 1, }

    try {
      const response = await fetch('api/riskstakeholders/' + userData.UserRole, {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.setState({ StakeholderId: 0 });
        this.loadData();
        ToastUtility.show({
          title: 'Risk Stakeholders', content: 'The stakeholders was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Stakeholders', content: 'There was an error saving the stakeholders!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveRootCauses() {
    const { handleNavigate } = this.context;
    const userData = getUserDetails();
    this.setState({ loading: true, showError: false, showSuccess: false, rootCausesModal: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: 0, RiskId: this.state.Id, ShortName: this.state.ShortName, Description: this.state.RiskDescription, DateAdded: new Date(), AddedBy: userData.Id, DateModified: new Date(), ModifiedBy: userData.Id, Status: 1, }

    try {
      const response = await fetch('api/rootcauses/' + userData.UserRole, {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.setState({ ShortName: "", RiskDescription: "" });
        this.loadData();
        ToastUtility.show({
          title: 'Root Causes', content: 'The root causes was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Root Causes', content: 'There was an error saving the root causes!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveControls() {
    
    const userData = getUserDetails();
    this.setState({ loading: true, showError: false, showSuccess: false, controlsEditModal: false, controlRootModal: false, controlConsequenceModal: false, controlsModal: true });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.ControlId, CompanyId: userData.CompanyId, RiskId: this.state.Id, ControlNumber: this.state.ControlNumber, LevelId: this.state.LevelId, ITId: this.state.ITId, AutomatedId: this.state.AutomatedId, ShortName: this.state.ShortName, Description: this.state.RiskDescription, NeedsAssurance: this.state.NeedsAssurance, Critical: this.state.Critical, ControlResponsiblePersonId: this.state.ControlResponsiblePersonId, DateAdded: new Date(), AddedBy: userData.Id, DateModified: new Date(), ModifiedBy: userData.Id, Status: 1, ConnectedRootCauses: JSON.stringify(this.state.ConnectedRootCauses), ConnectedConsequences: JSON.stringify(this.state.ConnectedConsequences) }

    try {
      const response = await fetch('api/controls', {
        method: data.Id === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.setState({ ShortName: "", RiskDescription: "" });
        this.loadData();
        ToastUtility.show({
          title: 'Controls', content: 'The controls was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Controls', content: 'There was an error saving the controls!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveConsequences() {
    const { handleNavigate } = this.context;
    const userData = getUserDetails();
    this.setState({ loading: true, showError: false, showSuccess: false, consequencesModal: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: 0, RiskId: this.state.Id, ShortName: this.state.ShortName, Description: this.state.RiskDescription, CompanyId: userData.CompanyId, DateAdded: new Date(), AddedBy: userData.Id, DateModified: new Date(), ModifiedBy: userData.Id, Status: 1, ConnectedPerspectives: JSON.stringify(this.state.ConnectedPerspectives) }

    try {
      const response = await fetch('api/consequences/' + userData.UserRole, {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.setState({
          ShortName: "", RiskDescription: "", ConnectedPerspectives: []
        });
        this.loadData();
        ToastUtility.show({
          title: 'Consequences', content: 'The consequences was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Consequences', content: 'There was an error saving the consequences!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

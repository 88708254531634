import React, { useContext, useEffect, useState } from "react";
import { confirm } from "react-confirm-box";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { clearUserDetails, getUserDetails } from "../helpers/authentication";
import { Context } from "../helpers/Context";
import { SetFilteredData, setIsFiltered } from "../helpers/Lookup";

const SideNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  //const { getBrands, brands } = useContext(Context);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { CompanyName } = useContext(Context);
  const handleLogout = async () => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to log out?", confirmStyles);
    if (result) {
      clearUserDetails();
      navigate("/login");
    }
  }

  const isActive = (to) => {
    return location.pathname.endsWith(to);
  };

  useEffect(() => {
    const list = document.querySelectorAll('.navigation li');

    //function activeLink() {
    //  list.forEach((item) => {
    //    item.classList.remove('hovered');
    //  });
    //  this.classList.add('hovered');
    //}

    //list.forEach((item) => item.addEventListener('mouseover', activeLink));

    const toggle = document.querySelector('.toggle');
    const navigation = document.querySelector('.navigation');
    const main = document.querySelector('.main');
    const container = document.querySelector('.container');

    toggle.onclick = function () {
      navigation.classList.toggle('active');
      main.classList.toggle('active');
      container.classList.toggle('active');
    };

    return () => {
      //list.forEach((item) => item.removeEventListener('mouseover', activeLink));
      toggle.removeEventListener('click', () => {
        navigation.classList.toggle('active');
        main.classList.toggle('active');
        container.classList.toggle('active');
      });
    };
  }, [CompanyName]);

  return (
    <div className="navContainer">
      <div className="navigation ">
        <ul className="d-flex flex-column justify-items-between h-100 pb-3">
          <li>
            <Link to="/">
              <span className="title heading py-3">On<span>Point</span>&nbsp;RMS</span>
            </Link>
          </li>

          <li className={isActive('/') ? 'active' : ''}>
            <Link to="/" onClick={() => { setIsFiltered(0); SetFilteredData([]); }}>
              <span className="icon">
                <i className="fa-solid fa-house ms-2"></i>
              </span>
              <span className="title"><small>Dashboard</small></span>
            </Link>
          </li>
          {getUserDetails()?.UserRole > 9 ? <li className={isActive('/admin') ? 'active' : ''}>
            <Link to="/admin" >
              <span className="icon">
                <i className="fa-solid fa-database ms-2"></i>
              </span>
              <span className="title"><small>Master Data</small></span>
            </Link>
          </li> : ""}
          <li className={isActive('/my-items') ? 'active' : ''}>
            <Link to="/my-items">
              <span className="icon">
                <i className="fa-solid fa-circle-exclamation ms-2"></i>
              </span>
              <span className="title"><small>Risks</small></span>
            </Link>
          </li>

          {getUserDetails()?.UserRole === 100 ?
            <li className={isActive('/admin/impersonate-company') ? 'active' : ''}>
              <Link to="/admin/impersonate-company">
                <span className="icon">
                  <i className="fa-solid fa-clone ms-2"></i>
                </span>
                <span className="title"><small>Impersonate Company</small></span>
              </Link>
            </li> : ""}
          {getUserDetails()?.UserRole > 9 ? <>
            <div className="heading  mt-3 mb-2" style={{ color: "#f1f1f1", borderBottom: "1px solid #E69C24" }}>General</div>

            <li className={isActive('/admin/companies') ? 'active' : ''}>
              <Link to="/admin/companies">
                <span className="icon">
                  <i className="fa-solid fa-building ms-2"></i>
                </span>
                <span className="title"><small>Companies</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/site-structure') ? 'active' : ''}>
              <Link to="/admin/site-structure">
                <span className="icon">
                  <i className="fa-solid fa-sitemap ms-2"></i>
                </span>
                <span className="title"><small>Sites</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/teams') ? 'active' : ''}>
              <Link to="/admin/teams">
                <span className="icon">
                  <i className="fa-solid fa-people-group ms-2"></i>
                </span>
                <span className="title"><small>Teams</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/users') ? 'active' : ''}>
              <Link to="/admin/users">
                <span className="icon">
                  <i className="fa-solid fa-user-group ms-2"></i>
                </span>
                <span className="title"><small>Users</small></span>
              </Link>
            </li>
            
            
            {/* 
            <li className={isActive('/admin/frequencies') ? 'active' : ''}>
              <Link to="/admin/frequencies">
                <span className="icon">
                  <i className="fa-solid fa-wave-square ms-2"></i>
                </span>
                <span className="title"><small>Frequencies</small></span>
              </Link>
            </li>
            */}
            

            <div className="heading mt-3 mb-2" style={{ color: "#f1f1f1", borderBottom: "1px solid #E69C24" }}>Risk Categorisation</div>
            <li className="d-none">
              <Link to="/admin/risk-statuses">
                <span className="icon">
                  <i className="fa-solid fa-palette ms-2"></i>
                </span>
                <span className="title"><small>Impact Levels</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/risk-types') ? 'active' : ''}>
              <Link to="/admin/risk-types">
                <span className="icon">
                  <i className="fa-solid fa-triangle-exclamation ms-2"></i>
                </span>
                <span className="title"><small>Risk Types</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/risk-categories') ? 'active' : ''}>
              <Link to="/admin/risk-categories">
                <span className="icon">
                  <i className="fa-solid fa-exclamation ms-2"></i>
                </span>
                <span className="title"><small>Risk Categories</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/business-objectives') ? 'active' : ''}>
              <Link to="/admin/business-objectives">
                <span className="icon">
                  <i className="fa-solid fa-briefcase ms-2"></i>
                </span>
                <span className="title"><small>Business Objectives</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/business-processes') ? 'active' : ''}>
              <Link to="/admin/business-processes">
                <span className="icon">
                  <i className="fa-solid fa-user-tie ms-2"></i>
                </span>
                <span className="title"><small>Business Processes</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/stakeholders') ? 'active' : ''}>
              <Link to="/admin/stakeholders">
                <span className="icon">
                  <i className="fa-solid fa-handshake ms-2"></i>
                </span>
                <span className="title"><small>Stakeholders</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/capitals') ? 'active' : ''}>
              <Link to="/admin/capitals">
                <span className="icon">
                  <i className="fa-solid fa-coins ms-2"></i>
                </span>
                <span className="title"><small>Capitals</small></span>
              </Link>
            </li>
            <div className="heading mt-3 mb-2" style={{ color: "#f1f1f1", borderBottom: "1px solid #E69C24" }}>Evaluation</div>

            <li className={isActive('/admin/impact-bands') ? 'active' : ''}>
              <Link to="/admin/impact-bands">
                <span className="icon">
                  <i className="fa-solid fa-palette ms-2"></i>
                </span>
                <span className="title"><small>Impact Bands</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/evaluation-perspectives') ? 'active' : ''}>
              <Link to="/admin/evaluation-perspectives">
                <span className="icon">
                  <i className="fa-solid fa-chart-simple ms-2"></i>
                </span>
                <span className="title"><small>Evaluation Perspectives</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/impact-perspective-diz') ? 'active' : ''}>
              <Link to="/admin/impact-perspective-diz">
                <span className="icon">
                  <i className="fa-regular fa-pen-to-square ms-2"></i>
                </span>
                <span className="title"><small>Impact Rating Descriptions</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/rating-tables') ? 'active' : ''}>
              <Link to="/admin/rating-tables">
                <span className="icon">
                  <i className="fa-solid fa-table ms-2"></i>
                </span>
                <span className="title"><small>Impact Rating Tables</small></span>
              </Link>
            </li>
            

            <div className="heading mt-3 mb-2" style={{ color: "#f1f1f1", borderBottom: "1px solid #E69C24" }}>Assurance</div>
            <li className={isActive('/admin/lines-of-assurances') ? 'active' : ''}>
              <Link to="/admin/lines-of-assurances">
                <span className="icon">
                  <i className="fas fa-shield"></i>
                </span>
                <span className="title"><small>Lines Of Assurance</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/assurance-providers') ? 'active' : ''}>
              <Link to="/admin/assurance-providers">
                <span className="icon">
                  <i className="fa-solid fa-id-badge ms-2"></i>
                </span>
                <span className="title"><small>Assurance Providers</small></span>
              </Link>
            </li>
            <li className={isActive('/admin/assurance-results-or-conclusions') ? 'active' : ''}>
              <Link to="/admin/assurance-results-or-conclusions">
                <span className="icon">
                  <i className="fa-solid fa-square-poll-vertical ms-2"></i>
                </span>
                <span className="title"><small>Assurance Conclusions</small>&nbsp;&nbsp;</span>
              </Link>
            </li>
            <li className={isActive('/admin/format-of-assurances') ? 'active' : ''}>
              <Link to="/admin/format-of-assurances">
                <span className="icon">
                  <i className="fa-solid fa-list ms-2"></i>
                </span>
                <span className="title"><small>Assurance Types</small></span>
              </Link>
            </li>
            <li className="d-none">
              <Link to="/admin/automated-full-review-template">
                <span className="icon">
                  <i className="fa-solid fa-folder-tree ms-2"></i>
                </span>
                <span className="title"><small>Automated Full Review Template</small></span>
              </Link>
            </li>
             </> : ""}

          <div className="footer-bottom">
            <div className="footer-heading mx-0 px-0 mt-3 mb-2" style={{ color: "#f1f1f1", borderBottom: "4px solid #E69C24", width: "200%" }}></div>

            <li className={isActive('/about') ? 'active' : ''}>
              <Link to="/about">
                <span className="icon">
                  <i className="fa-solid fa-circle-question"></i>
                </span>
                <span className="title"><small>About</small></span>
              </Link>
            </li>
            <li className={isActive('/support') ? 'active mb-3' : 'mb-3'}>
              <Link to="/support">
                <span className="icon">
                  <i className="fa-solid fa-square-phone"></i>
                </span>
                <span className="title"><small>Contact us / Support</small></span>
              </Link>
            </li>
            <span className="text-muted copyright"><small>&copy; {currentYear} OnPoint RMS. All rights reserved</small></span>
          </div>
        </ul>
      </div>

      <div className="main">
        <div className="topbar">
          <div className="toggle">
            <i className="fa-solid fa-bars"></i>
          </div>

          <div className="search text-center">
            <Link to="/">
              <span className="navbar-brand">
                On<span>Point</span>&nbsp;RMS
              </span>
              <div className="tiny">{getUserDetails()?.CompanyName}</div>
            </Link>
          </div>

          <div className="user">
            <Link onClick={() => handleLogout()} className="navbar-brand">
              <label className="">Sign Out</label>
              <span className="icon">
                <i className="fa-solid fa-right-from-bracket ms-2"></i>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { createRef, useEffect, useState } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Inject, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { CommandTemplate, ConfirmDialogStyles, SearchGridToolbar, SimpleToolbarSettings } from '../../../helpers/global';
import EngagementModal from '../EngagementModal';
import Attachments from '../Common/Attachments';
import Findings from '../Common/Findings';
import { fieldRequired } from '../../../helpers/validation';

const Controls = ({ RiskId, RootCausesData, ConsequenceData, ControlsData, UserList, EventHandler, ReloadHandler }) => {

  //const [capitalId, setCapitalId] = useState(0);
  //const [capitals, setCapitals] = useState();
  const [riskStatus, setRiskStatus] = useState();
  const [controlNumber, setControlNumber] = useState('');
  const [editId, setEditId] = useState(0);
  const [shortName, setShortName] = useState('');
  const [description, setDescription] = useState('');
  const [controlResponsiblePersonId, setControlResponsiblePersonId] = useState(0);
  const [critical, setCritical] = useState(false);
  const [needsAssurance, setNeedsAssurance] = useState(false);
  const [levelId, setLevelId] = useState(0);
  const [iTId, setITId] = useState(0);
  const [automatedId, setAutomatedId] = useState(0);
  const [connectedRootCauses, setConnectedRootCauses] = useState();
  const [connectedConsequences, setConnectedConsequences] = useState();

  const levels = [{ Title: "Unclassified", Id: 0 }, { Title: "Entity Level", Id: 1 }, { Title: "Activity Level", Id: 2 }];
  const isIT = [{ Title: "Unclassified", Id: 0 }, { Title: "IT General Control", Id: 1 }, { Title: "IT Application Control", Id: 2 }];
  const isAutomated = [{ Title: "Unclassified", Id: 0 }, { Title: "Automated", Id: 1 }, { Title: "Manual", Id: 2 }];

  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);

  const toggle = () => setEditModal(!editModal);

  const statusRef = createRef();


  const commandClick = async (args) => {

    switch (args.commandColumn.type) {
      case 'Delete':
        if (await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles)) {
          deleteItem(args.rowData.Id);
        }
        break;
      default: //edit
        editItem(args.rowData.Id);
        break;
    }
  }

  const addItem = () => { editItem(0); }

  const editItem = (id) => {
    if (id > 0) {
      const data = ControlsData.find((item) => { return item.Id === id });
      setEditId(data.Id);
      setControlNumber(data.ControlNumber);
      setShortName(data.ShortName);
      setDescription(data.Description);
      setControlResponsiblePersonId(data.ControlResponsiblePersonId);
      setCritical(data.Critical);
      setNeedsAssurance(data.NeedsAssurance);
      setLevelId(data.LevelId);
      setITId(data.ITId);
      setAutomatedId(data.AutomatedId);
      setConnectedRootCauses(data.ConnectedRootCauses);
      setConnectedConsequences(data.ConnectedConsequences);

    }
    else {
      //clear state fields
      setEditId(0);

      setControlNumber('');
      setShortName('');
      setDescription('');
      setControlResponsiblePersonId(0);
      setCritical(false);
      setNeedsAssurance(false);
      setLevelId(0);
      setITId(0);
      setAutomatedId(0);
      setConnectedRootCauses('');
      setConnectedConsequences('');
    }
    setEditModal(true);
  };

  const validateForm = () => {
    var valid = true;
    valid &= fieldRequired(shortName, 'tbShortNameError', '* required');

    valid &= fieldRequired(controlResponsiblePersonId, 'ddControlResponsiblePersonIdError', '* required');

    return valid;
  }


  const saveItem = async (event) => {
    event.stopPropagation();
    if (validateForm()) {
      const User = getUserDetails();
      const data = { id: editId, riskId: RiskId, shortName: shortName, description: description, controlResponsiblePersonId: controlResponsiblePersonId, critical: critical, needsAssurance: needsAssurance, levelId: levelId, iTId: iTId, automatedId: automatedId, companyId: User.CompanyId, connectedRootCauses: JSON.stringify(connectedRootCauses), connectedConsequences: JSON.stringify(connectedConsequences) };
      console.log(data);
      var bearer = 'Bearer ' + getAuthToken();

      try {
        const response = await fetch('/api/controls', {
          method: editId === 0 ? 'POST' : 'PUT',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          await response.json();
          if (ReloadHandler)
            ReloadHandler();
          ToastUtility.show({
            title: 'Controls', content: 'The control was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
          });
        }
        else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401)
            window.location.href = "/login";
        }

      } catch (e) {
        console.error(e);
        ToastUtility.show({
          title: 'Controls', content: 'There was an error saving the control!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
      toggle();
    }

  }

  const loadData = async () => {
    loadRiskStatus();
  }

  const loadRiskStatus = async () => {
    const User = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch(`api/controlstatus/getbyriskid/${RiskId}/${User.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log("Business Objectives status: ", data)
        setRiskStatus(data);
        setLoading(false);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const deleteItem = async (dataId) => {
    //if (await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles)) {
    const bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();
    try {
      const response = await fetch('/api/controls/' + userData.UserRole + "/" + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        if (ReloadHandler)
          ReloadHandler();

        ToastUtility.show({
          title: 'Risk Capitals', content: 'The capital was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Capitals', content: 'There was an error deleting the capital!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
      //}
    }
  }

  const renderCheckbox = (props) => {
    if (props.column.field === "Critical") {
      if (props.Critical)
        return (<div style={{ marginLeft: "auto", marginRight: "auto", width: "20px", height: "20px", border: "1px grey solid", display: "grid", placeContent: "center" }}><i className="fa-solid fa-check fs-6"></i></div>);
      else
        return (<div style={{ marginLeft: "auto", marginRight: "auto", width: "20px", height: "20px", border: "1px grey solid" }}></div>);
    }

    if (props.column.field === "NeedsAssurance") {
      if (props.NeedsAssurance)
        return (<div style={{ marginLeft: "auto", marginRight: "auto", width: "20px", height: "20px", border: "1px grey solid", display: "grid", placeContent: "center" }}><i className="fa-solid fa-check fs-6"></i></div>);
      else
        return (<div style={{ marginLeft: "auto", marginRight: "auto", width: "20px", height: "20px", border: "1px grey solid" }}></div>);
    }
  };

  const renderIsLinked = (props) => {
    const cnt = props.ConnectedConsequences.length + props.ConnectedRootCauses.length;
    if (cnt > 0)
      return (<></>)
    else
      return (<i className="fa-solid fa-link-slash text-danger me-2" title="This control has no linked root causes or consequences"></i>)
  };

  const handleRootCausesChange = (id) => {
    const updatedData = connectedRootCauses?.includes(id)
      ? connectedRootCauses.filter((dataId) => dataId !== id)
      : [...connectedRootCauses, id];

    setConnectedRootCauses(updatedData);
  };

  const handleConsequencesChange = (id) => {
    const updatedData = connectedConsequences?.includes(id)
      ? connectedConsequences.filter((dataId) => dataId !== id)
      : [...connectedConsequences, id];

    setConnectedConsequences(updatedData);
  };


  useEffect(() => {
    loadData();
    //console.log(ControlsData);
  }, []);



  if (!loading)
    return (
      <>

        <div className='text-center'><small>{riskStatus?.Percentage > 0 ? `${riskStatus?.Percentage}% - ${new Date(riskStatus?.DateModified).toISOString().split("T")[0]}` : "Not Yet Started"}</small></div>
        <div className='card'>
          <div className='card-header'>

            <table className="w-100">
              <tbody>
                <tr>
                  <td>Controls</td>
                  <td className="text-end"><i className="fa-regular fa-square-plus" onClick={addItem} title='Add Control'></i></td>
                </tr>
              </tbody>
            </table>
            <small>(Click the <strong>"+"</strong> icon to add a Control)</small>
          </div>
          <div className='card-body control-table'>

            <GridComponent dataSource={ControlsData} commandClick={commandClick} allowSorting={true}  >
              <ColumnsDirective>
                <ColumnDirective field='ControlNumber' width='25' headerText="" template={renderIsLinked} />
                <ColumnDirective field='ControlNumber' width='75' headerText="Control #" />
                <ColumnDirective field='ShortName' width='120' headerText="Short Name" />
                <ColumnDirective field='FindingCount' width='80' headerText="Findings" textAlign="center" />
                <ColumnDirective field='AttachmentCount' width='85' headerText="Attachments" textAlign="center" />
                <ColumnDirective field='Critical' width='75' headerText="Critical?" textAlign="center" template={renderCheckbox} />
                <ColumnDirective field='NeedsAssurance' width='100' headerText="Needs Assurance?" textAlign="center" template={renderCheckbox} />
                <ColumnDirective headerText='Actions' width='65' commands={CommandTemplate} />
              </ColumnsDirective>
              <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
            </GridComponent>

          </div>
          <div className="card-footer text-center">
            {ControlsData?.length > 0 ? <button onClick={() => statusRef?.current.showModal()} className='btn btn-info btn-sm'><i className="far fa-edit me-2"></i>Update Status</button> : ""}
          </div>
        </div>


        <Modal isOpen={editModal} toggle={toggle} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={toggle}>Control</ModalHeader>
          <ModalBody>
            <div className='row'>

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbShortName' name='tbShortName' placeholder='Short Name' type='text' htmlattributes={{ maxLength: '250' }} floatLabelType='Always' showClearButton={true} value={shortName} onChange={e => setShortName(e.value)} />
                <div id='tbShortNameError' className='error-message' />
              </div>

              <div className='mb-3 col-md-3'>
                <DropDownListComponent id='ddControlResponsiblePersonId' name='ddControlResponsiblePersonId' placeholder='Responsible Person (Control)' dataSource={[{ FullName: "-SELECT-", Id: 0 }, ...UserList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={controlResponsiblePersonId} onChange={e => setControlResponsiblePersonId(e.value)} />
                <div id='ddControlResponsiblePersonIdError' className='error-message' />
              </div>
              <div className='col-md-3 align-self-center'>
                <CheckBoxComponent id='cbCritical' name='cbCritical' label='Critical?' checked={critical} change={e => setCritical(e.checked)} /><br />
                <CheckBoxComponent id='cbNeedsAssurance' name='cbNeedsAssurance' label='Needs Assurance?' checked={needsAssurance} change={e => setNeedsAssurance(e.checked)} />
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbRiskDescription' name='tbRiskDescription' placeholder='Description' type='text' htmlattributes={{ maxLength: '500' }} floatLabelType='Always' showClearButton={true} value={description} onChange={e => setDescription(e.value)} />
              </div>

              {editId > 0 && <div className='col-md-12 mb-3 risk-status'>
                <div className="">
                  <Attachments Id={editId} Type={2} />
                  <Findings Id={editId} Type={2} />
                </div>
              </div>}
            </div>

            <h6 className='mt-3 mb-2 fw-bold border-bottom'>Control Classification</h6>

            <div className='row'>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddLevelId' name='ddLevelId' placeholder='Level' dataSource={levels} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={levelId} onChange={e => setLevelId(e.value)} />
              </div>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddITId' name='ddITId' placeholder='IT/Non-IT' dataSource={isIT} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={iTId} onChange={e => setITId(e.value)} />
              </div>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddAutoId' name='ddAutoId' placeholder='Automated/Manual' dataSource={isAutomated} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={automatedId} onChange={e => setAutomatedId(e.value)} />
              </div>
            </div>


            <div className="row">
              <div className="col-md-6">
                <h6 className='mt-3 mb-2 fw-bold border-bottom'>Root Causes</h6>
                {RootCausesData?.length ? RootCausesData.map((item, index) => (
                  <div className='mb-2 col-md-6' key={index}>
                    <CheckBoxComponent
                      id={`cbRootCause_${item.Id}`}
                      name={`cbRootCause_${item.Id}`}
                      type="checkbox"
                      label={item.ShortName}
                      checked={connectedRootCauses?.includes(item.Id)}
                      change={() => handleRootCausesChange(item.Id)}
                    />
                  </div>
                )) : <span className='text-center'>Create Root Causes first to link to a Control</span>}
              </div>
              <div className="col-md-6">
                <h6 className='mt-3 mb-2 fw-bold border-bottom'>Consequences</h6>
                {ConsequenceData?.length ? ConsequenceData.map((item, index) => (
                  <div className='mb-2 col-md-6' key={index}>
                    <CheckBoxComponent
                      id={`cbConsequence_${item.Id}`}
                      name={`cbConsequence_${item.Id}`}
                      type="checkbox"
                      label={item.ShortName}
                      checked={connectedConsequences?.includes(item.Id)}
                      change={() => handleConsequencesChange(item.Id)}
                    />
                  </div>
                )) : <span className='text-center'>Create Consequences first to link to a Control</span>}
              </div>
            </div>

          </ModalBody>
          <ModalFooter>
            <Button color="primary" size="sm" onClick={() => { }}>Engage with Control Assurance Plan <i className="fa-regular fa-circle-right ms-2" ></i></Button>
            <Button color="dark" size="sm" onClick={toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <EngagementModal key="mdlStatus" ref={statusRef} Title="Controls Status" EngagementSaved={loadRiskStatus} Data={{ Id: RiskId, PreviousPercentage: riskStatus?.Percentage, UserName: riskStatus?.UserName, PreviousDate: riskStatus?.DateModified, PreviousComment: riskStatus?.Note, Route: 'api/controlstatus/' + getUserDetails().UserRole }} />
      </>
    );
};

export default Controls;
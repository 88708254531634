import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { MDBBtn } from 'mdb-react-ui-kit';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setAuthToken, setUserDetails } from '../helpers/authentication';
import { SetCatastrophicLabel, SetImmaterialityLabel } from '../helpers/Lookup';
import { Context } from '../helpers/Context';
import { fieldRequired } from '../helpers/validation';

export class Login extends Component {
  static displayName = Login.name;
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      username: '',
      password: '',
      Checked: false,
    };
  }

  componentDidMount() {
    document.title = "OnPoint RMS :: Login";
  }

  attemptLogin = async (event) => {
    event.stopPropagation();
    let isValid = true;
    isValid &= fieldRequired(this.state.username, "errEmail", "* required", 'tbUsername');
    isValid &= fieldRequired(this.state.password, "errPassword", "* required", 'tbPassword');

    if (isValid) {
      this.Login();
    }
  }

  render() {
    return (
      <>
        <div className="login-container">
          <div className="">
            <div className="row">
              {/*<div className="col-md-6 mx-auto">*/}
              <div className="card px-0">
                <div className="card-header login-title">
                  <h5 className="card-title">On<span>Point</span> Risk Management System</h5>
                  Empowering Decisions with Precision
                </div>
                <div className="card-body">
                  <div className="mb-3">

                    <label>Email
                      <span className="error-message">&nbsp;</span>
                      <span id="errEmail" className="error-message" />
                    </label>

                    <input id="tbUsername" type='email' className='form-control form-control-sm' placeholder='Email' onChange={e => this.setState({ username: e.target.value }/*, () => fieldRequired(this.state.username, "errEmail", "* required", "errEmail")*/)} />
                  </div>

                  <label>Password
                    <span className="error-message">&nbsp;</span>
                    <span id="errPassword" className="error-message" />
                  </label>

                  <div className="input-group mb-3">
                    <input id="tbPassword" type={this.state.Checked ? 'text' : 'password'} className='form-control form-control-sm' aria-describedby="basic-addon" placeholder='Password' onChange={e => this.setState({ password: e.target.value }/*, () => fieldRequired(this.state.password, "errPassword", "* required", "errPassword")*/)} />
                    <span className="input-group-text bg-dark text-light" style={{ cursor: "pointer" }} onClick={() => { this.setState({ Checked: !this.state.Checked }) }} id="basic-addon">{this.state.Checked ? <i className="fas fa-eye-slash mx-0" title="Hide password"></i> : <i className="fas fa-eye mx-0" title="Show password"></i>}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    
                    <small className="align-self-start login">
                      <Link to="/forgot-password">Forgot password? </Link>
                    </small>
                  </div>
                </div>
                <div className="card-footer text-end">
                  <MDBBtn onClick={this.attemptLogin} size='sm'>Login <i className="fa-sharp fa-solid fa-chevron-right ms-2"></i></MDBBtn>
                  <div className="version">Version: 0.9</div>
                </div>

              </div>
              
            </div>
          </div>
        </div>
        
      </>
    );
  }

  async Login() {
    const { handleNavigate } = this.context;
    this.setState({ loading: true });

    try {

      const response = await fetch(`api/login/UserLogin?username=${this.state.username}&password=${this.state.password}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        this.setState({ loading: false });
        setAuthToken(data.Item1, new Date());
        setUserDetails(data.Item2);
        SetCatastrophicLabel(data.Item2.CatastrophicLabel);
        SetImmaterialityLabel(data.Item2.ImmaterialityLabel);
        //console.log(data.Item2);
        ToastUtility.show({
          title: "Login",
          content: "Login is successful",
          cssClass: "e-toast-success",
          position: { X: "Right", Y: "Bottom" },
          timeOut: 5000,
          showCloseButton: true,
        });
        handleNavigate("/");
      }
      else {
        this.setState({ loading: false });
        if (response.status === 400)
          ToastUtility.show({
            title: "Login",
            content: "Your account has been locked out",
            cssClass: "e-toast-info",
            position: { X: "Right", Y: "Bottom" },
            timeOut: 5000,
            showCloseButton: true,
          });
        else if (response.status === 504)
          ToastUtility.show({
            title: "Login",
            content: "There was an error logging you in, please try again after a few minutes",
            cssClass: "e-toast-danger",
            position: { X: "Right", Y: "Bottom" },
            timeOut: 5000,
            showCloseButton: true,
          });
        else
          ToastUtility.show({
            title: "Login",
            content: "Email/Password is invalid",
            cssClass: "e-toast-danger",
            position: { X: "Right", Y: "Bottom" },
            timeOut: 5000,
            showCloseButton: true,
          });
      }
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
      ToastUtility.show({
        title: "Login",
        content: "Email/Password is invalid",
        cssClass: "e-toast-danger",
        position: { X: "Right", Y: "Bottom" },
        timeOut: 5000,
        showCloseButton: true,
      });
    }
  }

}

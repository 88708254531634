/* eslint-disable default-case */
/* eslint-disable eqeqeq */
import { DropDownListComponent, DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { AboveAmountFilter, CapitalFilter, ObjectiveFilter, OnsetFilter, PeopleFilter, ProcessFilter, StakeholderFilter, TopNFilter } from '../../helpers/DashboardHelper';
import { GenerateSiteStructureTree, ShowLoader } from '../../helpers/global';
import { getIsFiltered, SetFilteredData, setIsFiltered } from '../../helpers/Lookup';
import SelectedRisk from './SelectedRisk';
export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);

    this.state = {
      Status: 0,
      primaryXAxis: { title: 'Days', interval: 1, valueType: 'DateTime', labelRotation: -45, },
      primaryyAxis: { title: 'Estimates Per Day', rangePadding: 'None', labelFormat: 'value' },
      Risks: [],
      FilteredRiskData: [],
      showFilteredRisks: getIsFiltered(),
      editData: [], loading: true,
      ddStatuses: [], ddTypes: [], ddPrimaryCategories: [], ddRegions: [], ddCountries: [], ddMines: [], ddOnsets: [], ddPeopleCats: [],
      ddObjectives: [], ddProcesses: [], ddCapitals: [], ddStakeholders: [], ddRatingPerspectives: [], ddPersons: [], ddFilterPersons: [],
      ddFilterTeams: [], ddOfWhat: [], ddSites: [], RatingBands: [],
      selStatus: 0,
      selType: 0,
      selPrimaryCategory: 0,
      selOnset: 0,
      selRegion: 0,
      selCountry: 0,
      selSite: 0,
      selObjective: 0,
      selProcess: 0,
      selCapital: 0,
      selStakeholder: 0,
      selPeopleCat: 0,
      selPerson: 0,
      selRatingPerspective: 0,
      selStrippingMethod: 0,
      HowMany: 0,
      AboveAmount: 0,
      selOfWhat: 0
    };
  }

  componentDidMount() {
    this.initDropdowns();
    this.loadData();
  }

  initDropdowns() {
    const ddOnsets = [
      { Title: "- All -", Id: 0 },
      { Title: "0 - 3 Months", Id: 1 },
      { Title: "4 - 6 Months", Id: 2 },
      { Title: "7 - 9 Months", Id: 3 },
      { Title: "10 - 12 Months", Id: 4 },
      { Title: "13 - 18 Months", Id: 5 },
      { Title: "19 - 24 Months", Id: 6 },
      { Title: "25 - 48 Months", Id: 7 },
      { Title: "> 48 Months", Id: 8 },
    ]

    const ddPeopleCats = [
      { Title: "- All -", Id: 0 },
      { Title: "Responsible Person", Id: 1 },
      { Title: "Responsible Team", Id: 2 },
      { Title: "Logger", Id: 3 },
      { Title: "Logger Team", Id: 4 },
    ]

    const ddStrippingMethods = [
      { Title: "Above a threshold", Id: 0 },
      { Title: "Top 'N'", Id: 1 }
    ]

    const ddOfWhat = [
      { Title: "POTENTIAL 'Likely Impact'", Id: 0 },
      { Title: "ACTUAL 'Likely Impact'", Id: 1 },
      { Title: "TARGET 'Likely Impact'", Id: 2 },
      { Title: "Gap between Potential & Actual", Id: 3 },
      { Title: "Gap between Actual & Target'", Id: 4 },
      { Title: "Potential 'Likely Financial Impact'", Id: 5 },
      //{ Title: "Potential 'Likely Financial Impact'", Id: 6 },
      //{ Title: "Potential 'Likely Financial Impact'", Id: 7 }
    ]
    this.setState({ ddOnsets, ddPeopleCats, ddStrippingMethods, ddOfWhat });
  }
  renderDashboard() {
    return this.state.showFilteredRisks ? this.renderFilteredRisk() : this.renderFilter();
  }

  renderFilter() {
    let siteStructureFields = { dataSource: GenerateSiteStructureTree(this.state.ddSites, true), value: 'id', text: 'name', parentValue: "pid", hasChildren: 'hasChild' };
    return (
      <>
        <h3 className="my-3 text-center">FILTER FOR THE RISKS YOU WISH TO SEE</h3>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6 mb-3 py-0">
                <Card className="mb-3">
                  <CardBody>
                    <h3 className='text-center mb-3'>Primary</h3>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label>Risk Status</label>
                        <DropDownListComponent dataSource={this.state.ddStatuses} fields={{ value: "Id", text: "Title" }} value={this.state.selStatus} select={e => this.setState({ selStatus: e.itemData.Id })} floatLabelType='Always' />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Risk Type</label>
                        <DropDownListComponent dataSource={this.state.ddTypes} fields={{ value: "Id", text: "Title" }} value={this.state.selType} select={e => this.setState({ selType: e.itemData.Id })} floatLabelType='Always' />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Risk Category</label>
                        <DropDownListComponent dataSource={this.state.ddPrimaryCategories} fields={{ value: "Id", text: "Title" }} value={this.state.selPrimaryCategory} select={e => this.setState({ selPrimaryCategory: e.itemData.Id })} floatLabelType='Always' />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Speed of Onset</label>
                        <DropDownListComponent dataSource={this.state.ddOnsets} fields={{ value: "Id", text: "Title" }} value={this.state.selOnset} select={e => (console.log(e.itemData.Id), this.setState({ selOnset: e.itemData.Id }))} floatLabelType='Always' />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="mb-3">
                  <CardBody>
                    <h3 className='text-center mb-3'>People</h3>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label>Category</label>
                        <DropDownListComponent dataSource={this.state.ddPeopleCats} fields={{ value: "Id", text: "Title" }} value={this.state.selPeopleCat} select={e => this.setState({ selPeopleCat: e.itemData.Id, selPerson: 0 })} floatLabelType='Always' />
                      </div>
                      <div className={"col-md-6 mb-3" + (this.state.selPeopleCat > 0 ? " d-none" : "")}>
                        <label>Person / Team</label>
                        <DropDownListComponent dataSource={this.state.ddPersons} fields={{ value: "Id", text: "Name" }} value={this.state.selPerson} change={e => (console.log(e), this.setState({ selPerson: e.itemData.Id }))} floatLabelType='Always' />

                      </div>
                      <div className="col-md-6 mb-3" hidden={this.state.selPeopleCat != 1 && this.state.selPeopleCat != 3}>
                        <label>Person</label>
                        <DropDownListComponent dataSource={this.state.ddFilterPersons} fields={{ value: "Id", text: "Name" }} value={this.state.selPerson} select={e => (console.log(e), this.setState({ selPerson: e.itemData.Id }))} floatLabelType='Always' />
                      </div>
                      <div className="col-md-6 mb-3" hidden={this.state.selPeopleCat != 2 && this.state.selPeopleCat != 4}>
                        <label>Team</label>
                        <DropDownListComponent dataSource={this.state.ddFilterTeams} fields={{ value: "Id", text: "Name" }} value={this.state.selPerson} select={e => (console.log(e), this.setState({ selPerson: e.itemData.Id }))} floatLabelType='Always' />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-md-6 mb-3 py-0">
                <Card className='mb-3'>
                  <CardBody>
                    <h3 className="text-center mb-3">Category</h3>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="">Business Objective</label>
                        <DropDownListComponent dataSource={this.state.ddObjectives} fields={{ value: "Id", text: "Title" }} value={this.state.selObjective} select={e => this.setState({ selObjective: e.itemData.Id })} floatLabelType='Always' />

                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="">Business Process</label>
                        <DropDownListComponent dataSource={this.state.ddProcesses} fields={{ value: "Id", text: "FullName" }} value={this.state.selProcess} select={e => this.setState({ selProcess: e.itemData.Id })} floatLabelType='Always' />

                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="">Capital</label>
                        <DropDownListComponent dataSource={this.state.ddCapitals} fields={{ value: "Id", text: "Title" }} value={this.state.selCapital} select={e => this.setState({ selCapital: e.itemData.Id })} floatLabelType='Always' />

                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="">StakeHolder</label>
                        <DropDownListComponent dataSource={this.state.ddStakeholders} fields={{ value: "Id", text: "Title" }} value={this.state.selStakeholder} select={e => this.setState({ selStakeholder: e.itemData.Id })} floatLabelType='Always' />

                      </div>
                    </div>
                  </CardBody>
                </Card>
                {/*</div>*/}
                {/*<div className="col-md-6 mb-3">*/}
                <Card className='mb-3'>
                  <CardBody>
                    <h3 className='text-center mb-3'>Site</h3>
                    
                    <div className="mb-3">
                      <label htmlFor="">Site</label>
                      {/*<DropDownListComponent dataSource={this.state.ddSites} fields={{ value: "Id", text: "Name" }} value={this.state.selSite} select={e => this.setState({ selSite: e.itemData.Id })} floatLabelType='Always' />*/}
                      <DropDownTreeComponent fields={siteStructureFields} id='ddSiteStructureFilterId' name='ddSiteStructureFilterId' showClearButton={false} placeholder='Site Structure' floatLabelType='Never' select={e => this.setState({ selSite: e.itemData.id })} value={[this.state.selSite]} />
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-12 mb-3">
                <Card>
                  <CardBody>
                    <h3 className="text-center mb-3">
                      Stripping out the most important risks only
                    </h3>
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="">Rating Perspective</label>
                        <DropDownListComponent dataSource={this.state.ddRatingPerspectives} fields={{ value: "Id", text: "Title" }} value={this.state.selRatingPerspective} select={e => this.setState({ selRatingPerspective: e.itemData.Id })} floatLabelType='Always' />

                      </div>
                      <div className="col-md-3">
                        <label htmlFor="">Stripping Method</label>
                        <DropDownListComponent dataSource={this.state.ddStrippingMethods} fields={{ value: "Id", text: "Title" }} value={this.state.selStrippingMethod} select={e => (this.setState({ selStrippingMethod: e.itemData.Id }))} floatLabelType='Always' />

                      </div>
                      <div className="col-md-3">
                        <div className="mb-3" hidden={this.state.selStrippingMethod == 0}>
                          <label htmlFor="">How Many? (N)</label>
                          <NumericTextBoxComponent value={this.state.HowMany} min={0} decimals={0} className='text-end pe-2' change={e => this.setState({ HowMany: e.value })} />
                          <small className="small text-muted"><b>Please Note:</b> To Show all cases set the field to 0</small>
                        </div>
                        <div className="mb-3" hidden={this.state.selStrippingMethod > 0}>
                          <label htmlFor="">Above what amount? (R)</label>
                          <NumericTextBoxComponent value={this.state.AboveAmount} min={0} decimals={2} className='text-end pe-2' change={e => this.setState({ AboveAmount: e.value })} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="">Of what?</label>
                        <DropDownListComponent dataSource={this.state.ddOfWhat} fields={{ value: "Id", text: "Title" }} value={this.state.selOfWhat} select={e => this.setState({ selOfWhat: e.itemData.Id })} floatLabelType='Always' />

                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-12 my-3 text-end">
                <hr />
                <Button color='primary' size='sm' onClick={this.filterRisks.bind(this)} >Show Selection</Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderFilteredRisk() {
    return <>
      <div className="row">
        <div className="col-md-6">
          {/* <h3 className='my-3'></h3> */}
        </div>
        <div className="col-md-6 text-end align-self-center">
          <Button type='button' color='dark' size='sm' outline onClick={() => { setIsFiltered(0); SetFilteredData([]); this.setState({ showFilteredRisks: false }) }}> Back to Filter</Button>
        </div>
      </div>
      <SelectedRisk RatingImpacts={this.state.RatingBands} />
    </>
  }

  onPeopleCatChange(evt) {
    const CatID = evt.itemData.Id;
    this.setState({ selPeopleCat: CatID, selPerson: 0 });
  }

  render() {
    const content = this.state.loading ? ShowLoader() : this.renderDashboard();
    return (
      <>
        <div className="row home-styles" hidden={this.state.showFilteredRisks}>
          <div className="col mb-3 ">
            <Link to="/my-items" className="card">
              {/*<a className="card" href="admin/risks">*/}
              <div className="card-body text-center">
                <i className="fa-solid fa-plus fa-4x"></i><br /><br />
                Add Risk
              </div>
            </Link>
          </div>
          <div className="col mb-3">
            <Link to="/processing" className="card">
              {/*<a href="my-items" className="card">*/}
              <div className="card-body text-center">
                <i className="fa-solid fa-pen-to-square fa-4x"></i><br /><br />
                Processing
              </div>
            </Link>
          </div>

          {getUserDetails().UserRole > 9 ? <div className="col mb-3">
            <Link to="/admin" className="card">
              <div className="card-body text-center">
                <i className="fa-solid fa-database fa-4x"></i><br /><br />
                Master Data
              </div>
            </Link>
          </div> : ""}
        </div>
        {content}
      </>
    );
  }

  async loadData() {
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();

    try {
      const response = await fetch("api/dashboard/GetFilterData/" + userData.CompanyId, {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const FilterData = await response.json();
        const ddStatuses = [{ Id: 0, Title: "- All -" }, ...FilterData.RiskStatuses];
        const ddTypes = [{ Id: 0, Title: "- All -" }, ...FilterData.RiskTypes];
        const ddPrimaryCategories = [{ Id: 0, Title: "- All -" }, ...FilterData.RiskCategories];
        const ddObjectives = [{ Id: 0, Title: "- All -" }, ...FilterData.BusinessObjectives];
        const ddProcesses = [{ Id: 0, FullName: "- All -" }, ...FilterData.BusinessProcesses];
        const ddCapitals = [{ Id: 0, Title: "- All -" }, ...FilterData.Capitals];
        const ddStakeholders = [{ Id: 0, Title: "- All -" }, ...FilterData.Stakeholders];
        //const ddSites = [{ Id: 0, Name: "- All -" }, ...FilterData.SiteStructures]
        const ddSites = [ ...FilterData.SiteStructures]

        const ddRatingPerspectives = [{ Id: 0, Title: "- All -" }];
        FilterData.EvaluationPerspective.map(item => ddRatingPerspectives.push({ Id: item.Id, Title: item.Title }));

        const ddPersons = [{ Id: 0, Name: "- All -" }];
        const ddFilterPersons = [{ Id: 0, Name: "- All -" }]
        const ddFilterTeams = [{ Id: 0, Name: "- All -" }]
        FilterData.Users.map(item => {
          ddPersons.push({ Id: "user-" + item.Id, Name: item.FirstName + " " + item.LastName, Type: 0 });
          ddFilterPersons.push({ Id: "user-" + item.Id, Name: item.FirstName + " " + item.LastName, Type: 0 });
        });
        FilterData.Teams.map(item => {
          ddPersons.push({ Id: "team-" + item.Id, Name: item.Name, Type: 1 });
          ddFilterTeams.push({ Id: "team-" + item.Id, Name: item.Name, Type: 1 });
        });

        //console.log("finif", FilterData);
        this.setState({ Risks: FilterData.Risks, RatingBands: FilterData.RatingBands, ddStatuses, ddTypes, ddPrimaryCategories, ddObjectives, ddProcesses, ddCapitals, ddStakeholders, ddPersons, ddFilterPersons, ddFilterTeams, ddRatingPerspectives, ddSites, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error("Dubsubd:", e);
    }
  }

  async filterRisks() {
    const State = this.state;
    let FilteredRiskData = this.state.Risks;
    if (State.selStatus > 0)
      FilteredRiskData = FilteredRiskData.filter(c => c.RiskStatusId == this.state.selStatus);

    if (State.selType > 0)
      FilteredRiskData = FilteredRiskData.filter(c => c.RiskTypeId == State.selType);

    if (State.selPrimaryCategory > 0)
      FilteredRiskData = FilteredRiskData.filter(c => c.RiskCategoryId == State.selPrimaryCategory);

    if (State.selOnset > 0)
      FilteredRiskData = OnsetFilter(State.selOnset, FilteredRiskData);

    FilteredRiskData = PeopleFilter(State.selPeopleCat, State.selPerson, FilteredRiskData);

    if (State.selSite > 0)
      FilteredRiskData = FilteredRiskData.filter(c => c.RiskSiteId == State.selSite);

    if (State.selObjective > 0)
      FilteredRiskData = ObjectiveFilter(State.selObjective, FilteredRiskData);

    if (State.selProcess > 0)
      FilteredRiskData = ProcessFilter(State.selProcess, FilteredRiskData);

    if (State.selCapital > 0)
      FilteredRiskData = CapitalFilter(State.selCapital, FilteredRiskData);

    if (State.selStakeholder > 0)
      FilteredRiskData = StakeholderFilter(State.selStakeholder, FilteredRiskData);

    if (State.selStrippingMethod > 0)
      FilteredRiskData = TopNFilter(State.selRatingPerspective, State.HowMany, State.selOfWhat, FilteredRiskData);
    else
      FilteredRiskData = AboveAmountFilter(State.selRatingPerspective, State.AboveAmount, State.selOfWhat, FilteredRiskData);

    //console.log("did", FilteredRiskData);

    SetFilteredData(FilteredRiskData);
    setIsFiltered(1);
    this.setState({ FilteredRiskData, showFilteredRisks: true });
  }


}

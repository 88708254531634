export const ParseRecurrenceRule = (rule) => {
  try {


    if (!rule) {
      return "No recurrance specified";
    }

    // Split the rule into key-value pairs
    const parts = rule.split(";");
    const ruleMap = Object.fromEntries(
      parts.map(part => {
        const [key, value] = part.split("=");
        return [key, value];
      })
    );

    // Parse the frequency
    const frequency = getFrequency(ruleMap["FREQ"]);

    // Parse the interval
    const interval = ruleMap["INTERVAL"] ? parseInt(ruleMap["INTERVAL"], 10) : 1;

    // Parse specific fields
    const daysOfWeek = ruleMap["BYDAY"] ? getDaysOfWeek(ruleMap["BYDAY"]) : null;
    const monthDay = ruleMap["BYMONTHDAY"] ? `on day ${ruleMap["BYMONTHDAY"]}` : null;
    const setPosition = ruleMap["BYSETPOS"] ? getSetPosition(ruleMap["BYSETPOS"]) : null;
    const month = ruleMap["BYMONTH"] ? getMonth(ruleMap["BYMONTH"]) : null;

    // Parse the COUNT
    const count = ruleMap["COUNT"] ? `${ruleMap["COUNT"]} occurrences` : null;

    // Parse the UNTIL date
    const until = ruleMap["UNTIL"] ? getUntilDate(ruleMap["UNTIL"]) : null;

    // Build the human-readable output
    const intervalText = interval > 1 ? `every ${interval} ${frequency}s` : `every ${frequency}`;
    const timeFrame = count ? `for ${count}` : until ? `until ${until}` : "indefinitely";

    // Build specific details
    const details = [
      setPosition ? `${setPosition} ${daysOfWeek}` : daysOfWeek,
      monthDay,
      month
    ]
      .filter(Boolean)
      .join(", ");

    return `Occurs ${intervalText} ${details ? `(${details})` : ""} ${timeFrame}.`;
  } catch (e) {
    return "No recurrance specified";
  }
}

function getFrequency(freq) {
  switch (freq?.toLowerCase()) {
    case "daily":
      return "day";
    case "weekly":
      return "week";
    case "monthly":
      return "month";
    case "yearly":
      return "year";
    default:
      return "unknown frequency";
  }
}

function getDaysOfWeek(byDay) {
  const dayMapping = {
    MO: "Monday",
    TU: "Tuesday",
    WE: "Wednesday",
    TH: "Thursday",
    FR: "Friday",
    SA: "Saturday",
    SU: "Sunday"
  };

  return byDay
    .split(",")
    .map(day => dayMapping[day] || day)
    .join(", ");
}

function getSetPosition(bySetPos) {
  bySetPos = parseInt(bySetPos, 10);
  //console.log("bySetPos", bySetPos)
  const positions = {

    1: "first",
    2: "second",
    3: "third",
    4: "fourth",
    //-1: "last"
  };
  if (bySetPos === -1) {
    return "last"
  }
  else
    return positions[bySetPos] || `${bySetPos}th`;
}

function getMonth(byMonth) {
  const months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  };

  return byMonth
    .split(",")
    .map(month => months[parseInt(month, 10)] || month)
    .join(", ");
}

function getUntilDate(until) {
  const date = new Date(
    until.slice(0, 4), // Year
    parseInt(until.slice(4, 6), 10) - 1, // Month (0-indexed)
    until.slice(6, 8), // Day
    until.slice(9, 11), // Hour
    until.slice(11, 13), // Minute
    until.slice(13, 15) // Second
  );

  if (isNaN(date.getTime())) {
    return "an unknown date";
  }

  return date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });

}

// Example usage
//const recurrenceRule = "FREQ=WEEKLY;BYDAY=MO,WE,FR;INTERVAL=1;UNTIL=20250126T121040Z;";
//console.log(parseRecurrenceRule(recurrenceRule));

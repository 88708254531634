import moment from "moment/moment";
import { useEffect } from 'react';
import { getUserDetails } from '../../../helpers/authentication';
import { CustomFixedDecimals } from "../../../helpers/global";


const Evolution = ({ RiskId, PotentialData, ActualData, TargetData, IsVisible }) => {


  const renderChangesBlock = (ChangePerc, Overall, TotalAmount, ReductionText, GapText, BottomText) => {
    return (
      <div className="position-relative">
        {ChangePerc ? <div className="fw-semibold"> {ChangePerc}% {ReductionText} </div> : ""}
        <i className="fa fa-arrow-left position-absolute top-50 start-0"></i>
        <div className="alert-gap text-center p-1">
          {GapText}
          {TotalAmount > 0 ?
            <>
              <span> {CustomFixedDecimals(Overall)} </span><br />
              {getUserDetails().Currency + " " + TotalAmount.toNumberString()}
            </> : ""}

        </div>
        <i className="fa fa-arrow-right position-absolute top-50 end-0"></i>
        <small>{BottomText}</small>
      </div>
    )
  }

  const getChangeStatus = (Type) => {
    let TotalDiff = 0;
    let OverallDiff = 0;
    let changePerc = 0;
    let potentialTotal = PotentialData?.TotalLikelyImpact;
    let potentialOverall = PotentialData?.OverallRating;
    let actualTotal = ActualData?.TotalLikelyImpact;
    let actualOverall = ActualData?.OverallRating;
    let targetTotal = TargetData?.TotalLikelyImpact;
    let targetOverall = TargetData?.OverallRating;

    switch (Type) {
      case "controlled":
        TotalDiff = Math.abs(potentialTotal - actualTotal);
        OverallDiff = Math.abs(potentialOverall - actualOverall);
        changePerc = Math.round((TotalDiff / potentialTotal) * 100);
        return renderChangesBlock(changePerc, OverallDiff, TotalDiff, <>reduction <br /> attained</>, <h6 className="fw-semibold">Controlled Gap</h6>, <p className="fw-bold">Addressed with EXISTING CONTROLS</p>)
      case "uncontrolled":
        TotalDiff = Math.abs(actualTotal - targetTotal);
        OverallDiff = Math.abs(actualOverall - targetOverall);
        changePerc = Math.round((TotalDiff / actualTotal) * 100);
        changePerc = Math.min(changePerc, 100);
        return renderChangesBlock(changePerc, OverallDiff, TotalDiff, <>reduction <br /> required</>, <h6 className="fw-semibold">Uncontrolled Gap</h6>, <p className="fw-bold">Close with GAP-CLOSING ACTIONS</p>)
      default: return;
    }
  }


  if (IsVisible)
    return (
      <>
        <div className="row my-4 align-items-center">
          <div className="col text-center align-self-center">
            <img src="/images/evolution-1.png" className="img-fluid my-auto" style={{ width: "100px" }} alt="Evolution 1" />
          </div>
          <div className="col text-center align-self-center">{getChangeStatus("controlled")}</div>
          <div className="col text-center">
            <img src="/images/evolution-2.png" className="img-fluid my-auto" style={{ width: "100px" }} alt="Evolution 2" />
          </div>
          <div className="col text-center align-self-center">{getChangeStatus("uncontrolled")}</div>
          <div className="col mb-3 text-center">
            <img src="/images/evolution-3.png" className="img-fluid my-auto mb-2" style={{ width: "100px" }} alt="Evolution 3" />
            <br />
            <h6 className="fw-semibold">Targeted Completion Date</h6>
            {TargetData?.TargetedCompletionDate ? <small className='border py-2 px-4'>{moment(TargetData.TargetedCompletionDate).format("YYYY/MM/DD")}</small> : <small className='border py-2 px-4'>Not Yet Set</small>}
          </div>
        </div>
      </>
    );
};

export default Evolution;
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { RecurrenceEditorComponent } from '@syncfusion/ej2-react-schedule';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAuthToken, getUserDetails } from "../../../helpers/authentication";
import { AssuranceStatuses, ScopesCheck, ScopesReview } from '../../../helpers/Lookup';
import { fieldRequired } from '../../../helpers/validation';
import Attachments from '../Common/Attachments';
import Findings from '../Common/Findings';



const EditAssurance = forwardRef((props, ref) => {

  const [editId, setEditId] = useState(0);
  const [editData, setEditData] = useState();

  const [controlId, setControlId] = useState();
  const [shortName, setShortName] = useState('');
  const [conrtolAssuranceNumber, setConrtolAssuranceNumber] = useState('');
  const [type, setType] = useState(0);
  const [scope, setScope] = useState();
  const [location, setLocation] = useState('');
  const [assuranceStatusId, setAssuranceStatusId] = useState(0);
  const [stopIfDeactivated, setStopIfDeactivated] = useState(false);
  const [details, setDetails] = useState('');
  const [clarificationNotes, setClarificationNotes] = useState('');
  const [assuranceResponsiblePersonId, setAssuranceResponsiblePersonId] = useState(0);
  const [triggeredEventsResponsiblePersonId, setTriggeredEventsResponsiblePersonId] = useState(0);
  const [loA, setLoA] = useState(0);
  const [assuranceProviderId, setAssuranceProviderId] = useState(0);
  const [serviceProvider, setServiceProvider] = useState('');
  const [questionnairesRequired, setQuestionnairesRequired] = useState(0);
  const [frequencyId, setFrequencyId] = useState(1);
  const [endRepeatId, setEndRepeatId] = useState(0);
  const [monthsToReview, setMonthsToReview] = useState(0);
  const [reviewStartingMonth, setReviewStartingMonth] = useState(new Date());
  const [startsOnDate, setStartsOnDate] = useState(new Date());
  const [recurranceDetails, setRecurranceDetails] = useState('');
  const [endsOnDate, setEndsOnDate] = useState(new Date());

  const [filteredAssuranceProviders, setFilteredAssuranceProviders] = useState();
  const [linesOfAssurance, setLinesOfAssurance] = useState();
  //const [repeatRule, setRepeatRule] = useState(0);

  const [scopeId, setScopeId] = useState(0);

  const [editModal, setEditModal] = useState(false);

  const recObject = useRef(null);
  const toggle = () => setEditModal(!editModal);


  const commandClick = async (args) => {
    switch (args.commandColumn.type) {
      case 'Delete':
        //if (await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles)) {
        //  deleteItem(args.rowData.id);
        //}
        break;
      default: //edit
        //editItem(args.rowData.id);
        break;
    }
  }

  const getLineOfAssuranceDisplay = (linesOfAssurance, lineOfAssuranceId) => {
    //console.log(linesOfAssurance, lineOfAssuranceId);
    const loa = linesOfAssurance.find(loa => loa.Id === lineOfAssuranceId);
    return loa ? `${loa.LoANum} - ${loa.Title}` : "0 - No Title";
  };

  const loadLinesOfAssurance = async () => {
    const userData = getUserDetails();

    try {
      var bearer = 'Bearer ' + getAuthToken();
      const response = await fetch(`api/linesofassurances/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      })

      if (response.ok) {
        const data = await response.json();
        const dataDisplay = data.map(item => ({
          ...item,
          LineOfAssuranceDisplay: getLineOfAssuranceDisplay(data, item.Id)
        }));
        //setAssuranceProviderList([{Id: 0, Title: '- Select Assurance Provider -'}, ...data]);
        setLinesOfAssurance([{ Id: 0, LineOfAssuranceDisplay: '- Select Line of Assurance -' }, ...dataDisplay])
        //this.setState({ linesOfAssurance: data });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const loadData = async (assuranceId) => {
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('/api/controlassurances/' + assuranceId, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setEditData(data);
        //console.log(data);
        setEditId(data.Id);
        //setId(data.id);
        //setControlId(data.ControlId);
        setShortName(data.ShortName);
        setConrtolAssuranceNumber(data.ConrtolAssuranceNumber);
        setType(data.Type);
        setLocation(data.Location);
        setAssuranceStatusId(data.AssuranceStatusId);
        setStopIfDeactivated(data.StopIfDeactivated);
        setDetails(data.Details);
        setClarificationNotes(data.ClarificationNotes);
        setAssuranceResponsiblePersonId(data.AssuranceResponsiblePersonId);
        setTriggeredEventsResponsiblePersonId(data.TriggeredEventsResponsiblePersonId);
        //setLoA(data.LoA);
        //setAssuranceProviderId(data.AssuranceProviderId);
        setServiceProvider(data.ServiceProvider);
        setQuestionnairesRequired(data.QuestionnairesRequired);
        setFrequencyId(data.FrequencyId);
        setEndRepeatId(data.EndRepeatId);
        setMonthsToReview(data.MonthsToReview);
        setReviewStartingMonth(data.ReviewStartingMonth);
        setStartsOnDate(data.StartsOnDate);
        setEndsOnDate(data.EndsOnDate);
        setRecurranceDetails(data.recurranceDetails);
        //setDateAdded(data.dateAdded);
        //setAddedBy(data.addedBy);
        //setDateModified(data.dateModified);
        //setModifiedBy(data.modifiedBy);
        //setStatus(data.status);
        setScopeId(data.ScopeId);
        //setCompanyId(data.companyId);

        //recObject?.current?.setRecurrenceRule('FREQ=DAILY;INTERVAL=2;COUNT=8');
        recObject?.current?.setRecurrenceRule(data.RecurranceDetails);
        setLoAProviders(data.LoA, data.AssuranceProviderId);

      }
      else {
        console.error(response);
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  const clearValues = () => {
    //console.log("clearing values");
    setEditId(0);
    setShortName('');
    setConrtolAssuranceNumber('');
    setLocation('');
    setAssuranceStatusId(0);
    setStopIfDeactivated(false);
    setDetails('');
    setClarificationNotes('');
    setAssuranceResponsiblePersonId(0);
    setTriggeredEventsResponsiblePersonId(0);
    setLoA(0);
    setAssuranceProviderId(0);
    setServiceProvider('');
    setQuestionnairesRequired(0);
    setFrequencyId(0);
    setEndRepeatId(0);
    setMonthsToReview(0);
    setReviewStartingMonth(null);
    setStartsOnDate(new Date());
    setEndsOnDate(new Date());
    setScopeId(0);
    setRecurranceDetails('');
    setFilteredAssuranceProviders([{ Id: 0, Title: '- Select Line of Assurance First -' }])
  }

  const EditAssurance = (ControlId, ControlTitle, AssuranceId, AssuranceType) => {
    ControlId = parseInt(ControlId, 10);
    AssuranceId = parseInt(AssuranceId, 10);
    AssuranceType = parseInt(AssuranceType, 10);
    //console.log("Control Id: " + ControlId);
    //console.log("Assurance Id: " + AssuranceId);

    //props.AssuranceProviders

    loadLinesOfAssurance();
    setControlId(ControlId);
    if (AssuranceType === 1)
      setScope(ScopesReview)
    else
      setScope(ScopesCheck)
    setEditId(AssuranceId);
    setType(AssuranceType);

    if (AssuranceId > 0) {
      console.log("should load...")
      loadData(AssuranceId);
    }
    else {
      console.log("clearing: ", AssuranceId)
      //console.log( AssuranceId > 0)
      clearValues();
    }
    toggle();
    //console.log(linesOfAssurance);
  }

  const setLoAProviders = (LoAId, ProviderId) => {
    //console.log(LoAId);
    LoAId = parseInt(LoAId, 10);
    if (LoAId === 0)
      setFilteredAssuranceProviders([{ Id: 0, Title: '- Select Line of Assurance First -' }]);
    else {
      setFilteredAssuranceProviders([{ Id: 0, Title: '- Select Assurance Provider -' }, ...props.AssuranceProviders.filter(item => item.LineOfAssuranceId === LoAId)]);
    }
    setAssuranceProviderId(ProviderId);
    setLoA(LoAId);

    
    /*
    setWithoutAssurances(ControlsData.filter(item => !item.Assurances.length));

      let withAssurances = ControlsData.filter(item => item.Assurances.length);
      if (assuranceFilter > 0) {
        withAssurances = withAssurances.map(item => ({ ...item, Assurances: item.Assurances.filter(assurance => assurance.AssuranceStatusId === assuranceFilter) }))
      } else if (assuranceFilter !== 3) {
        withAssurances = withAssurances.map(item => ({ ...item, Assurances: item.Assurances.filter(assurance => assurance.AssuranceStatusId !== 3) }))
      }

      if (withAssurances.length > 0) {
        withAssurances = withAssurances.map(item => ({ ...item, ControlAssuranceStatus: assuranceStatusData?.find(assurance => item.Id === assurance.ControlId) }))
      }*/
  }

  const onRepeaterChange = (args) => {
    setRecurranceDetails(args.value);
  }

  useImperativeHandle(ref, () => ({
    EditAssurance
  }));

  const validateForm = () => {
    var valid = true;

    valid &= fieldRequired(shortName, 'tbShortNameError', '* required');
    valid &= fieldRequired(location, 'tbLocationError', '* required');
    valid &= fieldRequired(assuranceStatusId, 'ddAssuranceStatusIdError', '* required');
    valid &= fieldRequired(details, 'tbDetailsError', '* required');
    valid &= fieldRequired(assuranceResponsiblePersonId, 'ddAssuranceResponsiblePersonIdError', '* required');
    valid &= fieldRequired(triggeredEventsResponsiblePersonId, 'ddTriggeredEventsResponsiblePersonIdError', '* required');
    valid &= fieldRequired(loA, 'tbLoAError', '* required');
    valid &= fieldRequired(assuranceProviderId, 'ddAssuranceProviderIdError', '* required');
    valid &= fieldRequired(serviceProvider, 'tbServiceProviderError', '* required');
    //valid &= fieldRequired(startsOnDate, 'tbStartsOnDateError', '* required');

    return valid;
  }

  const saveItem = async (event) => {
    event.stopPropagation();

    if (validateForm()) {
      const userData = getUserDetails();

      const data = { id: editId, controlId: controlId, companyId: userData.CompanyId, scopeId: scopeId, shortName: shortName, conrtolAssuranceNumber: conrtolAssuranceNumber, type: type, location: location, assuranceStatusId: assuranceStatusId, stopIfDeactivated: stopIfDeactivated, details: details, clarificationNotes: clarificationNotes, assuranceResponsiblePersonId: assuranceResponsiblePersonId, triggeredEventsResponsiblePersonId: triggeredEventsResponsiblePersonId, loA: loA, assuranceProviderId: assuranceProviderId, serviceProvider: serviceProvider, questionnairesRequired: questionnairesRequired, frequencyId: frequencyId, endRepeatId: endRepeatId, monthsToReview: monthsToReview, reviewStartingMonth: reviewStartingMonth, recurranceDetails: recurranceDetails, startsOnDate: startsOnDate, endsOnDate: endsOnDate };

      var bearer = 'Bearer ' + getAuthToken();

      try {
        const response = await fetch('/api/controlassurances', {
          method: editId === 0 ? 'POST' : 'PUT',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const data = await response.json();
          console.log("returned data: " + data);
          //loadData(data);
          if (props.OnReloadControls)
            props.OnReloadControls();
          ToastUtility.show({
            title: 'Control Assurance', content: 'The control assurance was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
          });
        }
        else {
          //console.log(response.status + ": " + response.statusText);
          console.log(response);
          if (response.status === 401)
            window.location.href = "/login";
        }

      } catch (e) {
        console.error(e);
        ToastUtility.show({
          title: 'Control Assurance', content: 'There was an error saving the control assurance!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
      toggle();
    }
  }


  return (
    <>
      <Modal isOpen={editModal} toggle={toggle} scrollable size="xl" backdrop="static">
        <ModalHeader toggle={toggle}>Assurances</ModalHeader>
        <ModalBody>

          <div className="row">
            <div className='mb-3 col-md-6'>
              <TextBoxComponent id='tbShortName' name='tbShortName' placeholder='Short Name' type='text' maxLength='500' floatLabelType='Always' showClearButton={true} value={shortName} onChange={e => setShortName(e.value)} />
              <div id='tbShortNameError' className='error-message' />
            </div>
            <div className='mb-3 col-md-3'>
              <TextBoxComponent id='tbType' name='tbType' placeholder='Type' disabled type='text' floatLabelType='Always' showClearButton={false} value={type === 1 ? 'Check' : 'Review'} />
              <div id='tbTypeError' className='error-message' />
            </div>
            <div className='mb-3 col-md-3'>
              <DropDownListComponent id='ddAssuranceStatusId' name='ddAssuranceStatusId' placeholder='Status' floatLabelType='Always' showClearButton={true} dataSource={AssuranceStatuses} fields={{ text: 'Title', value: 'Id' }} value={assuranceStatusId} onChange={e => setAssuranceStatusId(e.value)} />
              <div id='ddAssuranceStatusIdError' className='error-message' />
            </div>
            <div className='mb-3 col-md-6'>
              <TextBoxComponent id='tbLocation' name='tbLocation' placeholder='Location' type='text' maxLength='500' floatLabelType='Always' showClearButton={true} value={location} onChange={e => setLocation(e.value)} />
              <div id='tbLocationError' className='error-message' />
            </div>

            <div className='mb-3 col-md-3'>
              <DropDownListComponent id='ddScopeId' name='ddScopeId' placeholder='Scope of the Check' floatLabelType='Always' showClearButton={true} dataSource={scope} fields={{ text: 'Title', value: 'Id' }} value={scopeId} onChange={e => setScopeId(e.value)} />
              <div id='ddScopeIdError' className='error-message' />
            </div>
            <div className='mb-3 col-md-3 align-self-center'>
              <CheckBoxComponent id='cbStopIfDeactivated' name='cbStopIfDeactivated' type='checkbox' label='Stop If Deactivated' checked={stopIfDeactivated} change={e => setStopIfDeactivated(e.value)} /> &nbsp;
              <div id='cbStopIfDeactivatedError' className='error-message' />
            </div>

            <div className='mb-3 col-md-6'>
              <TextBoxComponent id='tbDetails' name='tbDetails' multiline placeholder='Details' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={details} onChange={e => setDetails(e.value)} />
              <div id='tbDetailsError' className='error-message' />
            </div>

            <div className='mb-3 col-md-6'>
              <TextBoxComponent id='tbClarificationNotes' name='tbClarificationNotes' multiline={true} placeholder='Clarification Notes' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={clarificationNotes} onChange={e => setClarificationNotes(e.value)} />
              <div id='tbClarificationNotesError' className='error-message' />
            </div>

            <div className='mb-3 col-md-4'>

              <DropDownListComponent id='ddLineOfAssuranceId' name='ddLineOfAssuranceId' placeholder='Line of Assurance' floatLabelType='Always' showClearButton={true} dataSource={linesOfAssurance} fields={{ text: 'LineOfAssuranceDisplay', value: 'Id' }} value={loA} onChange={e => setLoAProviders(e.value, 0)} />
              <div id='tbLoAError' className='error-message' />
            </div>

            <div className='mb-3 col-md-4'>
              <DropDownListComponent id='ddAssuranceProviderId' name='ddAssuranceProviderId' placeholder='Assurance Provider' floatLabelType='Always' showClearButton={true} dataSource={filteredAssuranceProviders} fields={{ text: 'Title', value: 'Id' }} value={assuranceProviderId} onChange={e => setAssuranceProviderId(e.value)} />
              <div id='ddAssuranceProviderIdError' className='error-message' />
            </div>

            <div className='mb-3 col-md-4'>
              <TextBoxComponent id='tbServiceProvider' name='tbServiceProvider' placeholder='Service Provider' type='text' maxLength='500' floatLabelType='Always' showClearButton={true} value={serviceProvider} onChange={e => setServiceProvider(e.value)} />
              <div id='tbServiceProviderError' className='error-message' />
            </div>

            <div className='mb-3 col-md-3'>
              <DropDownListComponent id='ddAssuranceResponsiblePersonId' name='ddAssuranceResponsiblePersonId' placeholder='Responsible Person (Assurance)' floatLabelType='Always' showClearButton={true} dataSource={props.Users} fields={{ text: 'DisplayName', value: 'Id' }} value={assuranceResponsiblePersonId} onChange={e => setAssuranceResponsiblePersonId(e.value)} />
              <div id='ddAssuranceResponsiblePersonIdError' className='error-message' />
            </div>

            <div className='mb-3 col-md-3'>
              <DropDownListComponent id='ddTriggeredEventsResponsiblePersonId' name='ddTriggeredEventsResponsiblePersonId' placeholder='Responsible Person (Triggered Events)' floatLabelType='Always' showClearButton={true} dataSource={props.Users} fields={{ text: 'DisplayName', value: 'Id' }} value={triggeredEventsResponsiblePersonId} onChange={e => setTriggeredEventsResponsiblePersonId(e.value)} />
              <div id='ddTriggeredEventsResponsiblePersonIdError' className='error-message' />
            </div>



            {/*<div className='mb-3 col-md-2 d-none'>*/}
            {/*  <NumericTextBoxComponent id='tbLoA' name='tbLoA' placeholder='LoA' floatLabelType='Always' showClearButton={true} format="##0" max={100} min={0} step={1} value={loA} onChange={e => setLoA(e.value)} />*/}

            {/*</div>*/}




            <div className='mb-3 col-md-2'>
              <NumericTextBoxComponent id='tbQuestionnairesRequired' name='tbQuestionnairesRequired' placeholder='Questionnaires Required' format="##0" max={100} min={0} step={1} floatLabelType='Always' showClearButton={true} value={questionnairesRequired} onChange={e => setQuestionnairesRequired(e.value)} />
              <div id='tbQuestionnairesRequiredError' className='error-message' />
            </div>
            <div className="mb-3 col-md-4 align-self-center">
              <Attachments Id={editId} Type={4} />
              &nbsp;&nbsp;
              <Findings Id={editId} Type={4} />
            </div>

          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="card h-100">
                <div className="card-body">
                  <h6>First Event/Start</h6>
                  <DatePickerComponent id='tbStartsOnDate' name='tbStartsOnDate' placeholder='' floatLabelType='Always' showClearButton={true} format='yyyy-MM-dd' value={startsOnDate} onChange={e => setStartsOnDate(e.value)} /> <div id='tbStartsOnDateError' className='error-message' />
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="card">
                <div className="card-body pb-0">
                  <h6>Repeat Schedule</h6>
                  <RecurrenceEditorComponent id='RecurrenceEditor' ref={recObject} dateFormat='yyyy-MM-dd' change={onRepeaterChange} ></RecurrenceEditorComponent>
                </div>
              </div>

            </div>
          </div>




          {/*<hr className="my-4" />*/}
          {/*<div className="row">*/}
          {/*  <div className="col-md-6 "><h4>Control Assurance Results</h4></div>*/}
          {/*  <div className="col-md-6 text-end align-self-center">*/}
          {/*    <Button color="primary" size="sm">Add New <i className="fas fa-plus-circle ms-2"></i></Button>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<GridComponent dataSource={editData} allowSorting={true} toolbar={SearchGridToolbar} commandClick={commandClick} >*/}
          {/*  <ColumnsDirective>*/}
          {/*    <ColumnDirective field='ControlId' width='100' headerText="ControlId" />*/}
          {/*    <ColumnDirective headerText='Actions' width='25' commands={CommandTemplate} />*/}
          {/*  </ColumnsDirective>*/}
          {/*  <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />*/}
          {/*</GridComponent>*/}





        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>

    </>

  )
})
export default EditAssurance;